import React, { useState, useEffect, useRef } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { Row, Col } from 'react-bootstrap'
import './App.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import {
  Radio,
  Switch,
  Select,
  Badge,
  Input,
  Cascader,
  Drawer,
  Form
} from 'antd'
import Button from '@mui/material/Button'
import Rijndael from 'rijndael-js'
import TextField from '@mui/material/TextField'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Modal from '@mui/material/Modal'
import Checkbox from '@mui/material/Checkbox'
import CryptoJS from 'crypto-js'
import CloseIcon from '@mui/icons-material/Close'
import Header from './Header'
import Expire from './Expire'
import Payment from './Payment'
import LoginCredential from './LoginCredential'
import Thankyou from './ThankYou'
import { Flag } from '@mui/icons-material'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '3px',
  boxShadow: 24,
  padding: 4
}

//import { saveAs } from 'file-saver';
//import { Form } from 'formik';

const totalSteps = 2
const Paymentmethod = () => {
  const [checked, setChecked] = useState(null)
  const [currentDate, setCurrentDate] = useState('')
  const [fielddate, setFielddate] = useState('')
  const navigate = useNavigate()
  useEffect(() => {
    const currentDateObj = new Date()
    const month = currentDateObj.getMonth() + 1
    const year = currentDateObj.getFullYear()

    setCurrentDate(`${month}/${year}`)
  }, [])

  const [values, setValues] = useState(0)

  const handleChange = (index, val) => {
    if (val.target.checked == true) {
      setValues(val.target.value)
      setChecked(prevSelected => (prevSelected === index ? null : index))
      setCvv('')
    } else {
      setValues(0)
      setChecked(prevSelected => (prevSelected === index ? null : index))
    }
  }

  const bottomRef = useRef(null)
  const [isloading, setisloading] = useState(false)
  const [isloading1, setisloading1] = useState(false)
  const teamFormRef = useRef()
  const currentmonth = new Date().getMonth()
  const currentYear = new Date().getFullYear()
  const futureYear = currentYear + 10

  const [Team] = Form.useForm()

  const [Arcform] = Form.useForm()

  const [open, setopen] = useState(false)

  const handleButtonClick = () => {
    // Show a success toast
    toast.success('Payment Successfull!')

    toast.error('Payment Failed! Please Check Your Account Details')
  }
  const [selectedCheckbox, setSelectedCheckbox] = useState(null)
  const [failedText, setFailedText] = useState('')
  const [showFailedLabel, setShowFailedLabel] = useState(false)
  const [text, settext] = useState(null)
  const [activeStep, setActiveStep] = useState(1)
  const [selectedTab, setSelectedTab] = useState('section1')
  const [cardSelected, setCardSelected] = useState(false)
  const [Formdata4, setFormdata4] = useState({
    accountid: '',
    accname: '',
    desc: ''
  })

  const [Formdata, setFormdata] = useState({
    CreditCardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    ardcode: '',
    cardaddress: '',
    city: '',
    state: '',
    zip: '',
    accountid: '',
    accname: '',
    desc: '',
    invoicenumber: '',
    amt: ''
  })
  const [Formdata3, setFormdata3] = useState({
    CreditCardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    ardcode: '',
    cardaddress: '',
    city: '',
    state: '',
    zip: '',
    invoicenumber: ''
  })
  const [Formdata2, setFormdata2] = useState({
    Routingnumber: '',
    accountno: '',
    conaccountno: '',
    namebank: '',
    payaddress: '',
    city: '',
    state: '',
    zip: ''
  })
  const [Formdata1, setFormdata1] = useState({
    Routingnumber: '',
    accountno: '',
    conaccountno: '',
    namebank: '',
    payaddress: '',
    city: '',
    state: '',
    zip: '',
    invoicenumber: '',
    accountid: '',
    accname: '',
    desc: '',
    amt: ''
  })
  const [cvv, setcvv] = useState({
    expiryMonth: ''
  })
  const [showelement, setshowelement] = useState(true)
  // const[showelement1,setshowelement1]=useState(true);
  const [radio, setradio] = useState(false)
  const [radio1, setradio1] = useState(false)
  const [errcvv, seterrcvv] = useState({})
  const [enable, setenable] = useState(false) //for spin animation in button
  const [disable, setdisable] = useState(false) //validation while pressing the keys in the field for credit card number
  const [disable1, setdisable1] = useState(false) //validation while pressing the keys in the field for ach payment
  const [showPopup1, setShowPopup1] = useState(false)
  const [api, setapi] = useState([])

  const [valid, setvalid] = useState(false)
  const [valid1, setvalid1] = useState(false)
  const [check, setcheck] = useState(false)

  const [show, setshow] = useState(true)

  const [vari, setvari] = useState({ id: [] })
  const [vari1, setvari1] = useState({
    id: '',
    accountid: '',
    invoiceno: '',
    invoiceamt: '',
    accname: '',
    desc: '',
    msg: ''
  })
  const [vari2, setvari2] = useState({
    id: '',
    accountid: ''
  })
  const [vari3, setvari3] = useState({
    id: '',
    accountid: '',
    invoiceno: '',
    invoiceamt: 1,
    amt: '',
    accname: '',
    desc: '',
    msg: ''
  })
  const [api1, setapi1] = useState({
    //api receives for ach payment
  })
  const [api3, setapi3] = useState({
    //api receives for credit card page
    status: null,
    msg: '',
    adddetails: ''
  })
  const [api4, setapi4] = useState({
    status: null,
    msg: '',
    adddetails: ''
  })
  const [expired, setExpired] = useState(false)
  const [Status, setStatus] = useState(false)
  const [failed, setFailed] = useState(false)

  var url = window.location.href
  const queryParams = new URLSearchParams(window.location.search)
  const term = queryParams.get('accountid')
  const CryptoJS = require('crypto-js')

  const decryptText = (cipherText, key, iv) => {
    const encryptionKey = CryptoJS.enc.Hex.parse(key)
    const Iv = CryptoJS.enc.Hex.parse(iv)

    const decrypted = CryptoJS.AES.decrypt(cipherText, encryptionKey, {
      iv: Iv
    })
    return decrypted.toString(CryptoJS.enc.Utf8)
  }

  // Example usage
  const keyHex = '00112233445566778899AABBCCDDEEFF'
  const ivHex = '101112131415161718191A1B1C1D1E1F'
  const cipherText = term.replaceAll(' ', '+')
  const decryptedText = decryptText(cipherText, keyHex, ivHex)
  //const decodedValue = atob(term);
  const parts = decryptedText.split('-')

  let [Error, setError] = useState({}) //Error for creditcard
  let [Error1, setError1] = useState({}) //Error for achpayment

  const [error, seterror] = useState(false)
  const [error1, seterror1] = useState(false)
  const [error2, seterror2] = useState(false)
  const [error3, seterror3] = useState(false)
  const [error4, seterror4] = useState(false)
  const [error5, seterror5] = useState(false)
  const [error6, seterror6] = useState(false)
  const [error7, seterror7] = useState(false)
  const [error8, seterror8] = useState(false)
  const [error9, seterror9] = useState(false)
  const [error10, seterror10] = useState(false)
  const [error11, seterror11] = useState(false)
  const [error12, seterror12] = useState(false)
  const [error13, seterror13] = useState(false)
  const [error14, seterror14] = useState(false)
  const [error15, seterror15] = useState(false)
  const [error16, seterror16] = useState(false)

  const getapi = async () => {
    await axios
      .get(
        'https://salesapi.transcredit.com/api/Prospect/GetAccountDetails?accountid1=' +
          term
      )
      .then(response => {
        if (response.data.msg == 'Link Expire - Already Paid') {
          setStatus(true)
          setExpired(true)
        } else {
          setapi(() => ({
            ...response.data,
            amt:
              response.data.Addonname === 'Portfolio'
                ? parseFloat(response.data.amt) + 100
                : response.data.Addonname === 'AR'
                ? parseFloat(response.data.amt) - 0
                : parseFloat(response.data.amt)
          }))
        }
      })

      .catch(err => console.log(err))
  }
  useEffect(() => {
    getapi()
  }, [])
  function validation () {
    setvalid(true)
    let err = {}
    if (check === true) {
      if (Formdata.CreditCardNumber == '') {
        err.CreditCardNumber = 'Card number is required*'
      }

      if (Formdata.expiryMonth === '') {
        err.expiryMonth = 'MM is Required*'
      }

      if (Formdata.expiryYear === '') {
        err.expiryYear = 'YYYY is required*'
      }

      if (
        Formdata.expiryMonth < currentmonth &&
        Formdata.expiryYear == currentYear
      ) {
        err.expiryMonth = 'MM is invalid*'
      }

      if (Formdata.ardcode === '') {
        err.ardcode = 'CVV2 is required*'
      } else {
        if (Formdata.ardcode.length < 3) {
          err.ardcode = 'CVV2 is invalid*'
        }
      }

      if (Formdata.cardaddress === '') {
        err.cardaddress = 'Billing Address is required*'
      } else {
        if (Formdata.cardaddress.length < 10) {
          err.cardaddress = 'Billing Address is required*'
        }
      }

      if (Formdata.city === '') {
        err.city = 'City is required*'
      } else {
        if (Formdata.city.length < 2) {
          err.city = 'City is invalid*'
        }
      }

      if (Formdata.state === '') {
        err.state = 'State is required*'
      } else {
        if (Formdata.state.length < 3) {
          err.state = 'State is invalid*'
        }
      }

      if (Formdata.zip === '') {
        err.zip = 'zip is required*'
      } else {
        if (Formdata.zip.length < 3) {
          err.zip = 'zip is invalid*'
        }
      }
      setError({ ...err })

      return Object.keys(err).length < 1
    }
  }

  let err1 = {}
  let errcvvv = {}
  function validation0 () {
    if (cvv.expiryMonth === '') {
      errcvvv.expiryMonth = 'required'
    } else if (cvv.expiryMonth.length < 3) {
      errcvvv.expiryMonth = 'Invalid CVV'
    }

    seterrcvv({ ...errcvvv })
    return Object.keys(errcvvv).length < 1
  }

  function validation1 () {
    if (Formdata1.Routingnumber === '') {
      err1.Routingnumber = 'Routing Number is required *'
    }

    if (Formdata1.accountno === '') {
      err1.accountno = 'Account Number is required *'
    }

    if (Formdata1.conaccountno === '') {
      err1.conaccountno = 'Confirm Account Number is required *'
    }

    if (Formdata1.namebank === '') {
      err1.namebank = 'Bank Name is required *'
    }

    if (Formdata1.payaddress === '') {
      err1.payaddress = 'Billing Address is required *'
    }

    if (Formdata1.city === '') {
      err1.city = 'City is required *'
    }

    if (Formdata1.state === '') {
      err1.state = 'State is required *'
    }

    if (Formdata1.zip === '') {
      err1.zip = 'Zip is required *'
    }

    setError1({ ...err1 })
    return Object.keys(err1).length < 1
    //return Object.keys(err1).length<1
  }

  const presseventcard = event => {
    // let erro={};
    var key = event.key
    var regex = /[0-9]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    }
  }

  const presseventmonth = event => {
    var key = event.key
    var regex = /[0-9]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    }
    if ((event.key = regex)) {
      Error.expiryMonth = ''
    }
  }
  const presseventyear = event => {
    var key = event.key
    var regex = /[0-9]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    }
    if ((event.key = regex)) {
      Error.expiryYear = ''
    }
  }
  const presseventcvv = event => {
    var key = event.key
    var regex = /[0-9]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    }
    if ((event.key = regex)) {
      Error.ardcode = ''
    }
  }

  const presseventcvv1 = event => {
    var key = event.key
    var regex = /[0-9]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    }
    if ((event.key = regex)) {
      errcvv.expiryMonth = ''
    }
  }

  const presseventchar = event => {
    var key = event.key
    var regex = /[a-zA-Z]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    }
  }

  const presseventcity = event => {
    var key = event.key
    var regex = /[a-zA-Z ]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    }
    if ((event.key = regex)) {
      Error.city = ''
    }
  }

  const presseventstate = event => {
    var key = event.key
    var regex = /[a-zA-Z ]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    }
    if ((event.key = regex)) {
      Error.state = ''
    }
  }

  const presseventaddr = event => {
    var key = event.key
    var regex = /[a-z0-9A-Z-, ]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    }
    if ((event.key = regex)) {
      Error.cardaddress = ''
    }
  }

  const presseventzip = event => {
    var key = event.key
    var regex = /[a-z0-9A-Z-, ]|\./

    // if( !regex.test(key) ) {
    //    event.preventDefault();
    //}
    if ((event.key = regex)) {
      Error.zip = ''
    }
  }

  const presseventrout = event => {
    var key = event.key
    var regex = /[0-9]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    } else {
      if ((event.key = regex)) {
        Error1.Routingnumber = ''
      }
    }
  }

  const presseventacc = event => {
    var key = event.key
    var regex = /[0-9]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    } else {
      if ((event.key = regex)) {
        Error1.accountno = ''
      }
    }
  }

  const presseventconacc = event => {
    var key = event.key
    var regex = /[0-9]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    } else {
      if ((event.key = regex)) {
        Error1.conaccountno = ''
      }
    }
  }

  const presseventnamebank = event => {
    var key = event.key
    var regex = /[a-zA-Z ]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    }

    if (event.key == regex) {
      Error1.namebank = ''
    }
  }

  const presseventcity1 = event => {
    var key = event.key
    var regex = /[a-zA-Z ]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    } else {
      if ((event.key = regex)) {
        Error1.city = ''
      }
    }
  }

  const presseventstate1 = event => {
    var key = event.key
    var regex = /[a-zA-Z ]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    } else {
      if ((event.key = regex)) {
        Error1.state = ''
      }
    }
  }

  const presseventpayaddr = event => {
    var key = event.key
    var regex = /[a-z0-9A-Z-, ]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    }
    if (event.key == regex) {
      Error1.payaddress = ''
    }
  }

  const presseventzip1 = event => {
    var key = event.key
    var regex = /[a-z0-9A-Z-,]|\./

    if (!regex.test(key)) {
      event.preventDefault()
    }
    if ((event.key = regex)) {
      Error1.zip = ''
    }
  }

  const submithandle1 = event => {
    event.preventDefault()
    validation1()
  }

  const eventhandler = event => {
    setFormdata(() => ({
      ...Formdata,
      [event.target.name]: event.target.value
    }))
  }

  if (disable === false) {
    if (
      Formdata.CreditCardNumber.length > 0 &&
      Formdata.CreditCardNumber.length < 15
    ) {
      Error.CreditCardNumber = 'Minimum 15 digits*'
      // setvalid(true)
    } else {
      if (
        Formdata.CreditCardNumber.length === 15 ||
        Formdata.CreditCardNumber.length === 16
      ) {
        Error.CreditCardNumber = ''
      } else if (error && Formdata.CreditCardNumber.length == 0) {
        Error.CreditCardNumber = 'Card number is required*'
      }
    }

    if (
      (Formdata.expiryMonth.length > 0 && Formdata.expiryMonth.length < 2) ||
      Formdata.expiryMonth > 12
    ) {
      Error.expiryMonth = 'MM is invalid*'
    } else if (error2 && Formdata.expiryMonth.length == 0) {
      Error.expiryMonth = 'MM is required*'
    } else if (Formdata.expiryMonth.length === 2 && Formdata.expiryMonth < 12) {
      Error.expiryMonth = ''
    }

    if (Formdata.expiryYear.length > 0 && Formdata.expiryYear.length < 4) {
      Error.expiryYear = 'YYYY is invalid*'
    } else if (
      (Formdata.expiryYear.length === 4 && Formdata.expiryYear < 2023) ||
      Formdata.expiryYear > 2032
    ) {
      Error.expiryYear = 'YYYY is invalid*'
    } else if (error3 && Formdata.expiryYear.length == 0) {
      Error.expiryYear = 'YYYY is required*'
    } else if (
      Formdata.expiryYear.length === 4 &&
      Formdata.expiryYear >= 2023 &&
      Formdata.expiryYear <= 2032
    ) {
      Error.expiryYear = ''
    }

    if (Formdata.ardcode.length > 0 && Formdata.ardcode.length < 3) {
      Error.ardcode = 'CVV2 is invalid*'
    } else if (error1 && Formdata.ardcode.length == 0) {
      Error.ardcode = 'CVV2 is required*'
    } else if (Formdata.ardcode.length == 3) {
      Error.ardcode = ''
    }

    if (Formdata.cardaddress.length > 0 && Formdata.cardaddress.length < 9) {
      Error.cardaddress = 'Billing Address is invalid*'
    } else if (error4 && Formdata.cardaddress.length == 0) {
      Error.cardaddress = 'Billing Address is required*'
    } else if (Formdata.cardaddress.length >= 9) {
      Error.cardaddress = ''
    }

    if (Formdata.city.length > 0 && Formdata.city.length < 3) {
      Error.city = 'city is invalid*'
    } else if (error5 && Formdata.city.length == 0) {
      Error.city = 'City is required*'
    } else if (Formdata.city.length >= 3) {
      Error.city = ''
    }

    if (Formdata.state.length > 0 && Formdata.state.length <= 1) {
      Error.state = 'state is invalid*'
    } else if (error6 && Formdata.state.length == 0) {
      Error.state = 'State is required*'
    } else if (Formdata.state.length >= 2) {
      Error.state = ''
    }

    if (Formdata.zip.length > 0 && Formdata.zip.length < 4) {
      Error.zip = 'zip is invalid*'
    } else if (error7 && Formdata.zip.length == 0) {
      Error.zip = 'Zip is a required*'
    } else if (Formdata.zip.length >= 4) {
      Error.zip = ''
    }
  }

  const creblur = () => {
    seterror(true)
  }
  const cvvblur = () => {
    seterror1(true)
  }
  const exmonblur = () => {
    seterror2(true)
  }
  const exyeblur = () => {
    seterror3(true)
  }
  const addblur = () => {
    seterror4(true)
  }
  const cityblur = () => {
    seterror5(true)
  }
  const stateblur = () => {
    seterror6(true)
  }
  const zipblur = () => {
    seterror7(true)
  }
  const routblur = () => {
    seterror8(true)
  }
  const accblur = () => {
    seterror9(true)
  }
  const conaccblur = () => {
    seterror10(true)
  }
  const bannameblur = () => {
    seterror11(true)
  }
  const banaddblur = () => {
    seterror12(true)
  }
  const accityblur = () => {
    seterror13(true)
  }
  const acstateblur = () => {
    seterror14(true)
  }
  const aczipblur = () => {
    seterror15(true)
  }
  const confoc = () => {
    seterror16(true)
  }

  // ach payment
  if (disable1 === false) {
    if (
      Formdata1.Routingnumber.length > 0 &&
      Formdata1.Routingnumber.length < 9
    ) {
      Error1.Routingnumber = 'Routing is invalid*'
    } else if (error8 && Formdata1.Routingnumber.length == 0) {
      Error1.Routingnumber = 'Routing is required*'
    } else if (Formdata1.Routingnumber.length == 9) {
      Error1.Routingnumber = ''
    }

    if (Formdata1.accountno.length > 0 && Formdata1.accountno.length < 15) {
      Error1.accountno = 'Account is invalid*'
    } else if (error9 && Formdata1.accountno.length == 0) {
      Error1.accountno = 'Account is required*'
    } else if (Formdata1.accountno.length == 15) {
      Error1.accountno = ''
    }

    if (
      error16 &&
      ((Formdata1.conaccountno.length > 0 &&
        Formdata1.conaccountno.length <= 14) ||
        Formdata1.accountno !== Formdata1.conaccountno)
    ) {
      Error1.conaccountno = 'Account Number does not match*'
    }

    if (error10 && Formdata1.conaccountno.length == 0) {
      Error1.conaccountno = 'Confirm Account Number is required*'
    } else if (
      error10 &&
      Formdata1.accountno.length !== Formdata1.conaccountno.length
    ) {
      Error1.conaccountno = 'Account Number does not match*'
    } else if (
      error16 &&
      Formdata1.conaccountno.length >= 15 &&
      Formdata1.accountno === Formdata1.conaccountno
    ) {
      Error1.conaccountno = ''
    }

    if (Formdata1.namebank.length > 0 && Formdata1.namebank.length < 10) {
      Error1.namebank = 'Bank Name is invalid*'
    } else if (error11 && Formdata1.namebank.length == 0) {
      Error1.namebank = 'Bank Name is required*'
    } else if (Formdata1.namebank.length == 10) {
      Error1.namebank = ''
    }

    if (Formdata1.payaddress.length > 0 && Formdata1.payaddress.length < 9) {
      Error1.payaddress = 'Billing Address is invalid*'
    } else if (error12 && Formdata1.payaddress.length == 0) {
      Error1.payaddress = 'Billing Address is required*'
    } else if (Formdata1.payaddress.length >= 10) {
      Error1.payaddress = ''
    }

    if (Formdata1.city.length > 0 && Formdata1.city.length < 3) {
      Error1.city = 'city is invalid*'
    } else if (error13 && Formdata1.city.length == 0) {
      Error1.city = 'city is required*'
    } else if (Formdata1.city.length >= 3) {
      Error1.city = ''
    }

    if (Formdata1.state.length > 0 && Formdata1.state.length <= 1) {
      Error1.state = 'state is invalid*'
    } else if (error14 && Formdata1.state.length == 0) {
      Error1.state = 'state is required*'
    } else if (Formdata1.state.length >= 2) {
      Error1.state = ''
    }

    if (Formdata1.zip.length > 0 && Formdata1.zip.length < 4) {
      Error1.zip = 'zip is invalid*'
    } else if (error15 && Formdata1.zip.length == 0) {
      Error1.zip = 'zip is required*'
    } else if (Formdata1.zip.length >= 2) {
      Error1.zip = ''
    }
  }

  const eventhandler1 = event => {
    setFormdata1(() => ({
      ...Formdata1,
      [event.target.name]: event.target.value
    }))
  }

  const eventhandler3 = (event, index) => {
    setcvv(() => ({ ...cvv, [event.target.name]: event.target.value }))

    settext(index)
  }
  const [data, setData] = useState(null)

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://saleswebapi.rcktechiees.com/api/Prospect/paymentaddach"
  //     ); // Replace with your API endpoint
  //     setData(response.data);
  //     console.log(data, "data");
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const downloadTxtFile = async () => {
    await axios
      .post(
        `https://salesapi.transcredit.com/api/Prospect/encodingstrings?accountno=${
          parts[0]
        }&Program=${parts[1]}&product=${encodeURIComponent(
          parts[2]
        )}&invoiceamt=${parts[4]}`
      )
      .then(res => {
        const linkSource = `data:application/pdf;base64,${res.data}`
        const downloadLink = document.createElement('a')
        const fileName = `${parts[1]} Receipt.pdf`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      })
      .catch(err => {
        toast.error('Download Failed')
      })
  }

  const handleDelete1 = async () => {
    let data = {
      id: values,
      accountid: api.accountid
    }
    setvari2((vari2.id = vari.id[0]))
    vari2.accountid = api.accountid
    const del = 1
    setShowPopup1(false)

    //setshowelement1( selectedCheckbox );
    setradio('2')

    await axios
      .post('https://salesapi.transcredit.com/api/Prospect/deletedetails', data)
      .then(getapi, setChecked(null))
      .catch(err => console.log(err))
    toast.success('Deleted Successfully')

    setvari({ id: [] })
    setvari2({ id: '' })
  }

  const pasteevent = event => {
    event.preventDefault()
  }

  const submithandle = event => {
    event.preventDefault()
  }

  let copy

  const handleTabChange = event => {
    //alert('jjj')
    setSelectedTab(event.target.value)
    // setActiveStep(event.target.value == "section1" ? 1 : 2 )
    setError(current => {
      const {
        CreditCardNumber,
        expiryMonth,
        expiryYear,
        ardcode,
        cardaddress,
        city,
        state,
        zip,
        ...rest
      } = current
      return rest
    })
    setError1(current => {
      const {
        Routingnumber,
        accountno,
        conaccountno,
        namebank,
        payaddress,
        city,
        state,
        zip,
        ...rest
      } = current
      return rest
    })
    seterrcvv({ expiryMonth: '' })
    setFormdata({
      ...Formdata,
      ...Formdata3
    })
    setFormdata1({
      ...Formdata1,
      ...Formdata2
    })
    seterror(false)
    seterror1(false)
    seterror2(false)
    seterror3(false)
    seterror4(false)
    seterror5(false)
    seterror6(false)
    seterror7(false)
    seterror8(false)
    seterror9(false)
    seterror10(false)
    seterror11(false)
    seterror12(false)
    seterror13(false)
    seterror14(false)
    seterror15(false)
    setvalid(false)
    setvalid1(false)
    setradio(false)
    setradio1(false)
    setvari({ id: [] })
    setSelectedCheckbox(null)
  }

  const [indexes, setindexes] = useState()
  const [changevalue, setChangeValue] = useState('')

  const radiochange = (event, index) => {
    setChangeValue(event.target.value)

    setradio1(false)
    setradio(true)
    //console.log(event)
    copy = { ...vari }
    copy.id.length = 0
    if (event.target.checked) {
      copy.id.push(event.target.value)
      seterrcvv({ expiryMonth: '' })
      setSelectedCheckbox(index)
      setcvv({ expiryMonth: '' })

      setCvv('')
      setHelperText('')
      setErr(false)
    }

    setvari(copy)
  }

  const radiochange1 = event => {
    setradio(false)
    setradio1(true)
  }
  const nextStep = async event => {
    setShowFailedLabel(false)
    if (Values === 1) {
      Team.validateFields()
        .then(res => Team.submit())
        .catch(err => {
          const error = err['errorFields'].map(item => item['errors'][0])
          const errornae = err['errorFields'].map(item => item['name'][0])

          if (error.includes('Please Enter Valid Month')) {
            toast.error('Please fill valid Month')
          }
          // error.includes('Please enter Valid Year')?toast.error('Please fill Valid Year'):null
          Team.scrollToField(errornae[0], { behavior: 'smooth' })
          let error1 = []
          const myObject = Team.getFieldsValue()
          errornae.forEach(item => {
            error1.push(myObject[item])
          })
          if (error1.includes(undefined) || error1.includes('')) {
            toast.error('Please fill Mandatory Fields')
          }
        })
    } else {
      Arcform.validateFields()
        .then(res => Arcform.submit())
        .catch(err => {
          toast.error('Please fill Mandatory Fields')
        })
    }

    //     setdisable(true);
    //     event.preventDefault();
    //     //setFormdata({...Formdata,...api});

    //     if(activeStep === 1 && selectedTab ==='section1' && cardSelected === true )
    //     {

    //   // Formdata.amt=api.amt for checking i commented
    //    Formdata.invoicenumber=api.invoicenumber
    //    Formdata.accountid=api.accountid
    //    Formdata.amt=api.amt
    //        setcheck(true)
    //        console.log('formdataunma',Formdata)
    //       setenable(false)
    //       const isvalid=validation();
    //        if(isvalid){
    //         setenable(true)

    //          await axios.post("http://salesapi.rcktechiees.com/api/Prospect/, ",Formdata)
    //        . then(res=>{
    //        api3.status=res.data.status
    //         api3.msg=res.data.msg
    //         api3.adddetails=res.data.adddetails

    //        },
    //       console.log('api3res',api3)

    //         )
    //       .catch(err=>console.log(err))
    //         setcheck(false)

    //         if(api3.adddetails === "Success"){
    //           setActiveStep(2)
    //           toast.success('Payment Successfully!')
    //           }
    //           else if(api3.adddetails === "Card already exists"){
    //             setCardSelected(true)
    //             setenable(false)
    //             toast.error("Already Exists")
    //           }
    //           else
    //            {
    //             setenable(false)
    //             setActiveStep(1)
    //             setSelectedTab('section1')
    //             setCardSelected(true)
    //             toast.error("Payment was failed")

    //           }
    //          }

    //       setdisable(false)
    //     }

    //     else if (activeStep === 1 && selectedTab==='section1' && cardSelected===false ){
    //      // setenable(false)
    //      var isvalid=false
    //      setenable(true)
    //        isvalid=validation0();

    //       if(isvalid){

    //       //   //setActiveStep(2);
    //       setvari1(vari1.id=vari.id[0],vari1.accname=Formdata.accname,vari1.accountid=api.accountid,vari1.amt=api.amt,vari1.desc=Formdata.desc,vari1.invoiceno=api.invoicenumber)
    //       await  axios.post("http://salesapi.rcktechiees.com/api/Prospect/invoicepayment",vari1)
    //         .then(res=>{vari1.msg=res.data.msg})
    //       .catch(err=>console.log(err))

    //   console.log('vari1',vari1)
    //   if(vari1.msg ==="Success")
    //   {
    //     setActiveStep(2);
    //     setvari({id:[]});
    //     toast.success('Payment Successfully!')
    //   }
    //   else{
    //     setenable(false)
    //     toast.error("Payment Failed");
    //   }
    //   setdisable(true)

    //       }
    //        else{
    //         setenable(false)
    //         toast.error("Please Fill Mandatory Fields");
    //       }

    // setvari1({id:''})
    // console.log('vari1nex',vari1)
    //         //setvari1(event.target.value);

    //      }

    //    else if(activeStep === 1 && selectedTab==='section2' && cardSelected===false||true) {
    //     setdisable1(false)
    //     seterror16(false)
    //     const isvalid=validation1();
    //     if(isvalid){
    //       Formdata1.invoicenumber=api.invoicenumber
    //       Formdata1.amt=api.amt
    //       Formdata1.accountid=api.accountid
    //   console.log('formdata11',Formdata1)
    //   setenable(true)
    //  await axios.post('http://salesapi.rcktechiees.com/api/Prospect/paymentaddach',Formdata1)
    //      .then(res=>{api1.adddetails=res.data.adddetails
    //                 api1.msg=res.data.msg
    //                  api1.status=res.data.status
    //                  })
    //       .catch(err=>console.log(err))
    //     // if(Object.keys(api).length>1){
    //        // setActiveStep(2)
    //     //   }
    //      if(api1.msg==='Successful'){
    //     //  console.log('Payment was successfull')
    //       setActiveStep(2)
    //       toast.success('Added Successfully!')
    //      }
    //      else if(api1.msg==='Already Exists'){
    //      toast.error("Account already exist")
    //      setenable(false)

    //      }
    //      else if(api1.status!==1){

    //      }
    // console.log('api1',api1)
    //     }

    // setvalid1(true)

    // //console.log('error1',Error1)
    //    }
    // if((activeStep===2)){
    // window.location.reload()
    // }
  }
  if (showPopup1 === true) {
    errcvv.expiryMonth = ''
  }
  const prevStep = () => {
    if (activeStep === 1 && Values == 1 && !open) {
      navigate('/?accountid=' + term)
    } else {
      setopen(false)
      setValue(1)
      setActiveStep(1)
      Team.setFieldsValue({
        CreditCardNumber: undefined,
        Expire_Date: undefined,
        Expire_Year: undefined,
        ardcode: undefined,
        Card_Holder_Name: undefined,
        Billing_Address: undefined,
        Company: undefined,
        State: undefined,
        Zip: undefined,
        City: undefined,
        Authorizedsignature: undefined,
        Date: undefined,
        PrintedName: undefined,
        Title: undefined
      })

      Arcform.setFieldsValue({
        Routingnumber: undefined,
        accountno: undefined,
        conaccountno: undefined,
        Bank_Name: undefined,
        Company: undefined,
        BillingAddr: undefined,
        City: undefined,
        State: undefined,
        Zip_Code: undefined,
        Authorizedsignature: undefined,
        Date: undefined,
        PrintedName: undefined,
        Title: undefined
      })
    }
    //     // setActiveStep(activeStep - 1);
    //   if(activeStep === 1 && selectedTab==='section1' && cardSelected===true)
    //   {
    //         setvalid(false)
    //       setActiveStep(1);
    //       setCardSelected(false);
    //       setSelectedTab(selectedTab)
    //       setError(current =>{
    //         const {CreditCardNumber,expiryMonth,expiryYear,ardcode,cardaddress,city,state,zip,...rest}=current;
    //         return rest;
    //       });
    //       setFormdata({...Formdata,...Formdata3
    //       });
    //       seterror(false)
    //       seterror1(false)
    //       seterror2(false)
    //       seterror3(false)
    //       seterror4(false)
    //       seterror5(false)
    //       seterror6(false)
    //       seterror7(false)
    //       setdisable(false)

    //    //   setFormdata({...Formdata,...Formdata4})
    //   }

    //   else if(activeStep === 2 && selectedTab ==='section1' && cardSelected === false ){
    //     console.log('variback',vari1)
    //   setActiveStep(1)
    //   setCardSelected(cardSelected)
    //   setSelectedTab(selectedTab)
    //   // setFormdata({...Formdata,...Formdata3
    //   // });
    //   setSelectedCheckbox(null);
    //   //setvari({id:[]})
    // //setvari1({...vari1.id,...vari3.id})
    // setcvv({expiryMonth:''})
    //   setenable(false)
    //   setcheck(true)
    //     }

    //     else if(activeStep ===2 && selectedTab==='section1' && cardSelected===true ){
    //       setActiveStep(1)
    //       setCardSelected(cardSelected)
    //       setSelectedTab(selectedTab)
    //       setError(current =>{
    //         const {CreditCardNumber,expiryMonth,expiryYear,ardcode,cardaddress,city,state,zip,...rest}=current;
    //         return rest;
    //       });
    //       setFormdata({...Formdata,...Formdata3});
    //       setapi3({...api3,...api4})
    //       setcheck(true)
    //       seterror(false)
    //       seterror1(false)
    //       seterror2(false)
    //       seterror3(false)
    //       seterror4(false)
    //       seterror5(false)
    //       seterror6(false)
    //       seterror7(false)
    //       setdisable(false)
    //       setenable(false)
    //       setvalid(false)
    //    //   setFormdata({...Formdata,...api});
    //    //console.log(api3,'api2')
    //      }

    //     else if(activeStep ===2 && selectedTab==='section2')
    //     {  setenable(false)
    //       setActiveStep(1)
    //       setSelectedTab(selectedTab)
    //       setFormdata1({...Formdata1,...Formdata2});
    //       setdisable1(false)
    //       seterror8(false)
    //       seterror9(false)
    //       seterror10(false)
    //       seterror11(false)
    //       seterror12(false)
    //       seterror13(false)
    //       seterror14(false)
    //       seterror15(false)
    //       setvalid1(false)

    //     }

    //     else {
    //       if (activeStep === 1 && selectedTab==='section2' && cardSelected===false||true){
    //       setActiveStep(1);
    //       setCardSelected(cardSelected);
    //       setSelectedTab('section1');
    //       setError1(current1 =>{
    //         const {Routingnumber,accountno,conaccountno,namebank,payaddress,city,state,zip,...rest}=current1;
    //         return rest;
    //       });
    //       setFormdata1({...Formdata1,...Formdata2
    //       });
    //     }
    //     setvalid1(false)

    //     }
  }
  const handleCardSelect = event => {
    setCardSelected(event.target.value === 'addNewCard')
  }

  const handleSpanClick = () => {
    setopen(true)
    //   setcheck(true)
    //   setdisable(false)
    setChecked(null)
    //   if(showPopup1){
    //     setCardSelected(false);

    //   }
    //   setvari({id:[]});

    //   setCardSelected(true);
    //  setcvv({expiryMonth:''})
    //   seterrcvv({expiryMonth:''})
    //   setradio(false)
    //   setradio1(false)

    //setFormdata({...Formdata,...Formdata4})
  }
  const [texts, settexts] = useState('')

  if (selectedTab == 'section1' && activeStep == 1 && cardSelected === true) {
  }

  const [Values, setValue] = useState(1)
  useEffect(() => {
    const currentDateObj = new Date()
    const month = currentDateObj.getMonth() + 1
    const year = currentDateObj.getFullYear()
    const day = currentDateObj.getDate()
    setFielddate(`${month}/${day}/${year}`)
    Team.setFieldsValue({
      Date: `${month}/${day}/${year}`
    })
  }, [open])

  useEffect(() => {
    const currentDateObj = new Date()
    const month = currentDateObj.getMonth() + 1
    const year = currentDateObj.getFullYear()
    const day = currentDateObj.getDate()
    setFielddate(`${month}/${day}/${year}`)
    Arcform.setFieldsValue({ Date: `${month}/${day}/${year}` })
  }, [Values])
  const [email, setEmail] = useState('')
  const onChange = e => {
    setValue(e.target.value)
    setChecked(null)
  }

  const [encryptecomplte, setencryptecomplte] = useState(0)
  const OnSubmit = async event => {
    setisloading1(true)
    let data = {}

    let credidcard = ''
    let cvv = ''

    if (Values === 1 && open == true) {
      const keysToLoop = ['CreditCardNumber', 'ardcode']

      for (const key of keysToLoop) {
        if (event.hasOwnProperty(key)) {
          const value = event[key]
          const dataToEncrypt = value
          const encryptionKey = CryptoJS.enc.Hex.parse(
            '00112233445566778899AABBCCDDEEFF'
          )
          const Iv = CryptoJS.enc.Hex.parse('101112131415161718191A1B1C1D1E1F')
          const cipherText = CryptoJS.AES.encrypt(
            dataToEncrypt,
            encryptionKey,
            { iv: Iv }
          ).toString()

          if (key == 'CreditCardNumber') {
            credidcard = cipherText
          } else {
            cvv = cipherText
          }
        }
      }

      if (credidcard !== '' && cvv !== '') {
        data = {
          CreditCardNumber: credidcard,
          expiryMonth: event.Expire_Date,
          expiryYear: event.Expire_Year,
          ardcode: cvv,
          cardaddress: event.Billing_Address,
          city: event.City,
          state: event.State,
          zip: event.Zip,
          accountid: api.accountid,
          accname: '',
          desc: '',
          invoicenumber: api.invoicenumber,
          amt: api.amt,
          payid: api.payid,
          esign: event.Authorizedsignature,
          date: event.Date,
          printedname: event.PrintedName,
          title: event.Title,
          Addon: api.Addonname === '' ? 0 : 1
        }

        await axios
          .post(
            'https://salesapi.transcredit.com/api/Prospect/paymentaddcc',
            data
          )
          .then(res => {
            // bottomRef.current.scrollIntoView({ behavior: 'smooth' })
            setisloading1(false)

            if (
              res.data.status == 0 &&
              res.data.adddetails == 'Card already exists'
            ) {
              setShowFailedLabel(true)
              setFailedText('Card already exists')
              // toast.error('Card already exists')
            }

            if (
              res.data.status == 0 &&
              res.data.msg == 'Error' &&
              res.data.adddetails == 'Error on payment please contact admin'
            ) {
              // toast.error('Error on payment.please contact admin.')
              setStatus(true)
              setFailed(true)
              // fail
            }
            if (
              res.data.status == 0 &&
              res.data.msg == 'Unsuccessful' &&
              res.data.adddetails == 'This transaction has not been approved'
            ) {
              setShowFailedLabel(true)
              setFailedText(res.data.Reason)
              // toast.error(`${res.data.Reason}`)
              // setStatus(true);
              // setFailed(true);
              // fail
            }
            if (
              res.data.status == 0 &&
              res.data.msg == 'Unsuccessful' &&
              res.data.adddetails == 'Insufficient Amount to Charge'
            ) {
              setShowFailedLabel(true)
              setFailedText('Insufficient Amount to Charge')
              // toast.error('Insufficient Amount to Charge')
            }
            if (
              (res.data.adddetails.status == 1 &&
                res.data.adddetails.msg == 'Success') ||
              res.data.adddetails.invoicepayment == 'Mail sent successfully'
            ) {
              setEmail(res.data.adddetails.email)
              toast.success('Payment Successfull')
              // handleOpen()
              settitle('Login')
              setActiveStep(2)
              setopen(false)
            }

            if (
              res.data.adddetails.status == 0 &&
              res.data.adddetails.msg == 'Unsuccessful' &&
              res.data.adddetails.invoicepayment ==
                'This transaction has not been approved'
            ) {
              setShowFailedLabel(true)
              setFailedText(res.data.adddetails.Reason)
              //toast.error(`${res.data.adddetails.Reason}`)
              // setStatus(true);
              // setFailed(true);
            }

            if (
              res.data.adddetails.status == 0 &&
              res.data.adddetails.msg == 'Unsuccessful' &&
              res.data.adddetails.invoicepayment ==
                'Insufficient Amount to Charge'
            ) {
              setShowFailedLabel(true)
              setFailedText(res.data.adddetails.Reason)
              // toast.error(`${res.data.adddetails.Reason}`)
              // setFailed(true);
            }
          })
          .catch(() => {
            setisloading1(false)
            setStatus(true)
            setFailed(true)
          })
      }
    } else {
      let accountnum = ''
      let conaccountnum = ''
      let routeno = ''

      const keysToLoop = ['Routingnumber', 'accountno', 'conaccountno']

      for (const key of keysToLoop) {
        if (event.hasOwnProperty(key)) {
          const value = event[key]
          const dataToEncrypt = value
          const encryptionKey = CryptoJS.enc.Hex.parse(
            '00112233445566778899AABBCCDDEEFF'
          )
          const Iv = CryptoJS.enc.Hex.parse('101112131415161718191A1B1C1D1E1F')
          const cipherText = CryptoJS.AES.encrypt(
            dataToEncrypt,
            encryptionKey,
            { iv: Iv }
          ).toString()

          if (key == 'Routingnumber') {
            routeno = cipherText
          }

          if (key == 'accountno') {
            accountnum = cipherText
          }
          if (key == 'conaccountno') {
            conaccountnum = cipherText
          }
        }
      }

      if (routeno !== '' && accountnum !== '' && conaccountnum != '') {
        data = {
          Routingnumber: routeno,
          accountno: accountnum,
          conaccountno: conaccountnum,
          namebank: event.Bank_Name,
          payaddress: event.BillingAddr,
          city: event.City,
          state: event.State,
          zip: event.Zip_Code,
          invoicenumber: api.invoicenumber,
          accountid: api.accountid,
          accname: '',
          desc: '',
          amt: api.amt,
          payid: api.payid,
          esign: event.Authorizedsignature,
          date: event.Date,
          printedname: event.PrintedName,
          title: event.Title,
          Addon: api.Addonname === '' ? 0 : 1
        }

        await axios
          .post(
            'https://salesapi.transcredit.com/api/Prospect/paymentaddach',
            data
          )
          .then(res => {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' })
            setisloading1(false)

            if (
              res.data.adddetails.status == 1 &&
              res.data.adddetails.paymentapi == 'Mail sent successfully' &&
              res.data.adddetails.msg == 'success'
            ) {
              settitle('Login')
              toast.success('Payment Successfull')
              setActiveStep(2)
            } else if (
              res.data.adddetails.status == 1 &&
              res.data.adddetails.msg == 'Mail sent successfully' &&
              res.data.adddetails.paymentsuccess == 'success'
            ) {
              settitle('Login')
              toast.success('Payment Successfull')
              setActiveStep(2)
            } else if (
              res.data.status == 0 &&
              res.data.msg == 'Error' &&
              res.data.adddetails == 'Insufficient Amount to paid'
            ) {
              toast.error('Insufficient Amount to paid')
            } else if (res.data.status == 0 && res.data.msg == 'Error') {
              // toast.error('Error on Payment. Contact Admin')
              setStatus(true)
              setFailed(true)
            } else if (res.data.status == 0 && res.data.msg == 'Error') {
              toast.error('Error on Payment. Contact Admin')
            } else if (
              res.data.status == 0 &&
              res.data.msg == 'Unsuccessful' &&
              res.data.adddetails == 'This transaction has not been approved'
            ) {
              toast.error(`${res.data.Reason}`)
            } else {
              toast.error('Payment Failed')
              setStatus(true)
              setFailed(true)
            }

            if (
              res.data.adddetails?.status == 0 &&
              res.data.adddetails?.paymentsuccess == 'fail' &&
              res.data.adddetails?.msg == 'Mail sent failed'
            ) {
              setEmail(res.data?.email)
              toast.success('Payment Successfull')
              // handleOpen()
              settitle('Login')
              setActiveStep(2)
            }
          })
          .catch(() => {})
      }
    }
  }

  const [title, settitle] = useState('Pay')
  const InvoicePayment = async event => {
    if (title == 'Pay') {
      if (checked == null) {
        setErr(true)
        toast.error('Please Choose a Credit Card')
      } else {
        let data = {
          id: values,
          accname: '',
          accountid: api.accountid,
          amt: api.amt,
          desc: '',
          invoiceno: api.invoicenumber,
          Cvv2: cvv2,
          payid: api.payid,
          Addon: api.Addonname === '' ? 0 : 1
        }

        if (api.accountid != '' && api.amt != '' && api.invoicenumber != '') {
          setisloading(true)

          await axios
            .post(
              'https://salesapi.transcredit.com/api/Prospect/checkcvv',
              data
            )

            .then(res => {
              bottomRef.current.scrollIntoView({ behavior: 'smooth' })
              setisloading(false)

              if (
                res.data.msg == 'Unsuccessful' &&
                res.data.status == '0' &&
                res.data.invoicepayment == 'Invalid CVV Number'
              ) {
                toast.error('Invalid CVV')
                setCvv('')
                setErr(true)
              } else if (
                res.data.invoicepayment.msg == 'Unsuccessful' &&
                res.data.invoicepayment.status == '0' &&
                res.data.invoicepayment.invoicepayment ==
                  'Insufficient Amount to Charge'
              ) {
                toast.error('Insufficient Amount to Pay')
                setCvv('')
                // setErr(true)
              } else if (
                res.data.invoicepayment.msg == 'Unsuccessful' &&
                res.data.invoicepayment.status == 0 &&
                res.data.invoicepayment.invoicepayment ==
                  'This transaction has not been approved'
              ) {
                toast.error(`${res.data.invoicepayment.Reason}`)
                // setCvv("");
                // setErr(true)
              } else if (
                res.data.invoicepayment.status === 1 &&
                res.data.invoicepayment.msg === 'Success'
              ) {
                // && (res.data.invoicepayment.invoicepayment === 'Mail sent successful'|| res.data.invoicepayment.invoicepayment === 'Mail sent failed')
                toast.success('Payment Successfull')
                settitle('Login')
                setActiveStep(2)
              } else if (
                res.data.status == 1 &&
                res.data.msg == 'Success' &&
                res.data.invoicepayment == 'Mail sent failed'
              ) {
                toast.success('Payment Successfull')
                settitle('Login')
                setActiveStep(2)
              } else if (
                (res.data.invoicepayment.status =
                  0 &&
                  res.data.invoicepayment.msg == 'Unsuccessful' &&
                  res.data.invoicepayment.splist ==
                    'This transaction has not been approved')
              ) {
                // setFailed(true);
                // setStatus(true);
                toast.error(`${res.data.invoicepayment.Reason}`)
              }

              // else{
              //   setStatus(true);
              //   setFailed(true);
              // }
            })
            .catch(err => {
              setisloading(false)
              setStatus(true)
              setFailed(true)
              toast.error('Payment Failed')
              setCvv('')
            })
        }
      }
    }
    if (title == 'Login') {
      const url = 'https://portal.transcredit.com/'
      window.open(url, '_blank')
      // settitle('Pay')
    }
  }
  const validateConfirmAccountNumber = (_, value) => {
    const accountNumber = Arcform.getFieldValue('accountno')

    if (value && value !== accountNumber) {
      return Promise.reject('Account Numbers Do Not Match')
    }

    return Promise.resolve()
  }

  const validateConfirmmonth = (_, value) => {
    const Date = Team.getFieldValue('Expire_Date')
    const month = Team.getFieldValue('Expire_Year')
    if (Date < currentmonth && month == 2023) {
      return Promise.reject('MM is invalid')
    }

    return Promise.resolve()
  }

  const [err, setErr] = useState(false)
  const [helperText, setHelperText] = useState('')

  const [cvv2, setCvv] = useState('')

  const handleCvvChange = event => {
    // Remove non-numeric characters and update the state
    const numericCvv = event.target.value.replace(/[^0-9]/g, '')
    setCvv(numericCvv)

    if (event.target.value.length < 3) {
      setErr(true)
      setHelperText('CVV must be atleast 3 Digits')
    } else {
      setErr(false)
      setHelperText('')
    }
  }

  const [open1, setOpen1] = React.useState(false)
  const handleOpen = () => setOpen1(true)
  const handleClose = () => setOpen1(false)
  const backToHome = val => {
    if (val === 'Failed') {
      // setActiveStep(1);
      setStatus(false)
      setFailed(false)
    }
    // else if(val === 'ACHSuccess'){
    //   setActiveStep(1);
    // }else if(val === 'loginCredential'){
    //   setActiveStep(1);
    //   setValue(1)
    // }
  }
  const renderContent = () => {
    if (activeStep === 1) {
      return (
        <div>
          {/* Existing code */}
          {/* Billing Step */}
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='row' style={{ borderBottom: '1px solid #E2E2E2' }}>
              <div className='col-lg-6 col-md-6 col-sm-6 col-6 col-6'>
                <p className='billpara'>Billing</p>
                {/* <Button onClick={handleOpen}>popup</Button> */}
                <Modal
                  open={open1}
                  onClose={handleClose}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                  hideBackdrop={true}
                >
                  <Box sx={style}>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <h4
                        style={{
                          fontSize: '18px',
                          margin: '0',
                          color: '#344767',
                          fontWeight: '700',
                          marginTop: '15px'
                        }}
                      >
                        Login Credentials
                      </h4>
                      <img
                        src='https://d1qhh2rihbnflk.cloudfront.net/images/Mask-group.svg'
                        style={{
                          width: '4.5em',
                          marginRight: '15px',
                          marginTop: '-10px'
                        }}
                      ></img>
                    </Box>

                    <Typography sx={{ fontSize: '14px', marginTop: '10px' }}>
                      Your Program activation is currently in progress. Please
                      keep an eye on your email inbox at{' '}
                      <span style={{ color: '#337AB7' }}>anu@gmail.com.</span>{' '}
                      Once the process is complete, you'll receive your
                      credentials to access the Program.
                    </Typography>
                    <Box sx={{ textAlign: 'center', marginTop: '10px' }}>
                      <Button
                        onClick={handleClose}
                        style={{
                          borderRadius: '5px',
                          background:
                            'linear-gradient(219deg, #49A3F1 0%, #2C85EB 100%)',
                          boxShadow:
                            '0px 1px 5px 0px rgba(52, 71, 103, 0.15), 0px 3px 1px -2px rgba(52, 71, 103, 0.20), 0px 3px 3px 0px rgba(52, 71, 103, 0.15)',
                          fontSize: '14px',
                          textTransform: 'capitalize',
                          color: '#fff',
                          padding: '4px 20px'
                        }}
                      >
                        Got it
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              </div>
              {Values == 1 && (
                <div className='col-lg-6 col-md-6 col-sm-6 col-6 col-6'>
                  <div className='fortwoimg'>
                    <div className='col'>
                      <img
                        style={{ width: '13em' }}
                        src='https://d1qhh2rihbnflk.cloudfront.net/images/Card_images.png'
                      />
                    </div>
                    {/* <div className='col'>
                    <img src="https://d1qhh2rihbnflk.cloudfront.net/images/visa.png" />
                    <img style={{ width: '2em' }} src="https://d1qhh2rihbnflk.cloudfront.net/images/visa.svg" />
                  </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='radio-tabs-container'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='row'>
                <div className='fortworad'>
                  {/* <Radio.Group onChange={onChange}  defaultValue={1} >

{
  Values===1 ? (
    <>
     <Button style={{height:'40px'}}>
                     <Radio  value={1}>Credit Card</Radio>
                    </Button>


                     <Button style={{height:'40px'}} >
                     <Radio value={2} >ACH Payment</Radio>
                    </Button>
    </>
  )
  :
  (
    <>
     <Button style={{height:'40px'}} >
      <Radio value={2} >ACH Payment</Radio>
      </Button>
    </>
  )
}

                    </Radio.Group> */}

                  <Radio.Group
                    className='radio-group'
                    onChange={onChange}
                    defaultValue={1}
                    value={Values}
                  >
                    {Values === 1 && (open === false || open === true) && (
                      // <Button className='radio-btn' variant="outlined" style={{ height: '40px', marginRight: '25px', borderColor: 'Highlight', width: '186px' }}>
                      <Radio
                        className='radio-text'
                        buttonBg={'red'}
                        value={1}
                        style={{
                          marginRight: '25px',
                          color: 'rgb(0, 56, 118)',
                          padding: '8px 25px',
                          backgroundColor: 'rgba(25, 118, 210, 0.04)',
                          border: '1px solid #1976d2',
                          borderRadius: '4px',
                          fontWeight: '600',
                          textTransform: 'uppercase'
                        }}
                      >
                        Credit Card
                      </Radio>
                      // </Button>
                    )}

                    {(Values === 2 || Values == 1) && open === false && (
                      // <Button className='radio-btn' variant="outlined" style={{ height: '40px', borderColor: 'Highlight', width: '186px' }}>
                      <Radio
                        className='radio-text'
                        style={{
                          color: `${
                            Values === 2 ? 'rgb(0, 56, 118)' : '#616161'
                          }`,
                          padding: '8px 25px',
                          backgroundColor: `${
                            Values === 2 ? 'rgba(25, 118, 210, 0.04)' : '#fff'
                          }`,
                          border: `1px solid ${
                            Values === 2 ? '#1976d2' : '#616161'
                          }`,
                          borderRadius: '4px',
                          fontWeight: '600',
                          textTransform: 'uppercase'
                        }}
                        value={2}
                      >
                        ACH Payment
                      </Radio>
                      // </Button>
                    )}
                  </Radio.Group>

                  {/* {(activeStep === 1 && (selectedTab==='section1'))
                  &&
                    <label className={`radio-tab new-label-class ${selectedTab === 'section1' ? 'selected' : ''}`} style={{border: selectedTab === 'section1' ?'1.5px solid #003876': '1.5px solid #616161', color: selectedTab === 'section1' ?'#003876': '#616161'}}>
        <input
        className='new-input-class'
          type="radio"
          name="tabs"
          value="section1"
          checked={selectedTab === 'section1'}
          onChange={handleTabChange}
        />
        Credit Card
      </label>
    }
      <>
  {
    (activeStep === 1 && (selectedTab==='section1'||'section2') && cardSelected===false) && (
      <label className={`radio-tab new-label-class ${selectedTab === 'section2' ? 'selected' : ''}`} style={{border: selectedTab === 'section2' ?'1.5px solid #003876': '1.5px solid #616161', color: selectedTab === 'section2' ?'#003876': '#616161'}}>
        <input
         className='new-input-class'
          type="radio"
          name="tabs"
          value="section2"
       
          checked={selectedTab === 'section2'}
          onChange={handleTabChange}
        />
        ACH Payment
      </label>
    )
    }
      </> */}
                </div>
              </div>
              <div className='row' style={{ marginTop: '20px' }}>
                {Values === 1 && (
                  <div>
                    {open == true ? (
                      <>
                        <Form
                          className='billing-name'
                          ref={teamFormRef}
                          layout='vertical'
                          scrollToFirstError={true}
                          form={Team}
                          onFinish={OnSubmit}
                        >
                          <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='row'>
                              <div className='col-lg-6 col-md-6 col-sm-6'>
                                <Form.Item
                                  onKeyPress={event => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                  label='Card Number'
                                  name={'CreditCardNumber'}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Card Number'
                                    },
                                    {
                                      validator: (rule, value) => {
                                        if (value && value.length < 10) {
                                          return Promise.reject(
                                            'Please enter valid Card Number'
                                          )
                                        }
                                        return Promise.resolve()
                                      }
                                    }
                                  ]}
                                >
                                  <Input
                                    maxLength={16}
                                    style={{ height: '40px' }}
                                    placeholder='Enter Card Number'
                                  ></Input>
                                </Form.Item>
                              </div>
                              <div className='col-lg-3 col-md-3 col-sm-3'>
                                <Form.Item
                                  onKeyPress={event => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                  label='Expiration'
                                  name={'Expire_Date'}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Month'
                                    },
                                    {
                                      validator: (rule, value) => {
                                        if (value) {
                                          const currentMonth =
                                            new Date().getMonth() + 1 // Adding 1 because getMonth() returns values from 0 to 11
                                          const currentYear =
                                            new Date().getFullYear()

                                          const enteredMonth = parseInt(
                                            value,
                                            10
                                          )

                                          const currentYears =
                                            Team.getFieldValue('Expire_Year')

                                          if (
                                            (currentYear == currentYears &&
                                              enteredMonth < currentMonth) ||
                                            enteredMonth > 12 ||
                                            enteredMonth < 1 ||
                                            value.length < 1
                                          ) {
                                            return Promise.reject(
                                              'Please Enter Valid Month'
                                            )
                                          }

                                          // You may want to add additional logic to validate the year if necessary

                                          return Promise.resolve()
                                        }
                                        return Promise.resolve()
                                      }
                                    }
                                  ]}
                                >
                                  <Input
                                    maxLength={2}
                                    style={{ height: '40px' }}
                                    placeholder='MM'
                                  ></Input>
                                </Form.Item>
                              </div>
                              <div
                                className='col-lg-3 col-md-3 col-sm-3 expireyear'
                                style={{ marginTop: '29px' }}
                              >
                                <Form.Item
                                  onKeyPress={event => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                  label=''
                                  name={'Expire_Year'}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Year'
                                    },
                                    {
                                      validator: (rule, value) => {
                                        if (
                                          (value && value < currentYear) ||
                                          (value && value > futureYear)
                                        ) {
                                          return Promise.reject(
                                            'Please enter Valid Year'
                                          )
                                        }

                                        return Promise.resolve()
                                      }
                                    }
                                  ]}
                                >
                                  <Input
                                    style={{ height: '40px' }}
                                    maxLength={4}
                                    placeholder='YYYY'
                                  ></Input>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='row'>
                              <div className='col-lg-6 col-md-6 col-sm-6'>
                                <Form.Item
                                  onKeyPress={event => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                  label='CVV Code'
                                  name={'ardcode'}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Cvv Code'
                                    },
                                    {
                                      min: 3,
                                      message: 'Please enter valid code'
                                      // validator: (rule, value) => {
                                      //   if (value && value.length < 3) {
                                      //     return Promise.reject('Please enter valid code');
                                      //   }
                                      //   return Promise.resolve();
                                      // }
                                    }
                                  ]}
                                >
                                  <Input
                                    maxLength={4}
                                    minLength={3}
                                    style={{ height: '40px' }}
                                    placeholder='Enter CVV Code'
                                  ></Input>
                                </Form.Item>
                              </div>
                              <div className='col-lg-6 col-md-6 col-sm-6'>
                                <Form.Item
                                  label='Card Holder Name'
                                  name={'Card_Holder_Name'}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Card Holder Name'
                                    }
                                    // {

                                    //   validator: (rule, value) => {

                                    //     if (value && (value.length < 4)) {
                                    //       return Promise.reject('Please Enter Valid Card Holder Name');
                                    //     }
                                    //     return Promise.resolve();
                                    //   }
                                    // }
                                  ]}
                                >
                                  <Input
                                    style={{ height: '40px' }}
                                    placeholder='Enter Card Holder Name'
                                  ></Input>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='row'>
                              <div className='col-lg-6 col-md-6 col-sm-6'>
                                <Form.Item
                                  label='Company Name'
                                  name={'Company'}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Company Name'
                                    },
                                    {
                                      // validator: (rule, value) => {
                                      //   if (value && (value.length < 4)) {
                                      //     return Promise.reject('Please Enter Valid Company Name');
                                      //   }
                                      //   return Promise.resolve();
                                      // }
                                    }
                                  ]}
                                >
                                  <Input
                                    style={{ height: '40px' }}
                                    placeholder='Enter Company Name'
                                  ></Input>
                                </Form.Item>
                              </div>
                              <div className='col-lg-6 col-md-6 col-sm-6 Billing_Address'>
                                <Form.Item
                                  label='Billing Address'
                                  name={'Billing_Address'}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Please enter Billing Address'
                                    },
                                    {
                                      // validator: (rule, value) => {
                                      //   if (value && (value.length < 4)) {
                                      //     return Promise.reject('Please Enter Valid Billing Address');
                                      //   }
                                      //   return Promise.resolve();
                                      // }
                                    }
                                  ]}
                                >
                                  <Input
                                    style={{ height: '40px' }}
                                    placeholder='Enter Billing Address'
                                    //  suffix={<i class="bi bi-geo-alt-fill" style={{ fontSize: '19px', color: '#9A9A9A' }} />}
                                  ></Input>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='row'>
                              <div className='col-lg-4 col-md-4 col-sm-4 City'>
                                <Form.Item
                                  label='City'
                                  name={'City'}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Please enter City'
                                    }
                                  ]}
                                >
                                  <Input
                                    style={{ height: '40px' }}
                                    placeholder='Enter City'
                                  ></Input>
                                </Form.Item>
                              </div>
                              <div className='col-lg-4 col-md-4 col-sm-4 State'>
                                <Form.Item
                                  label='State'
                                  name={'State'}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Please enter State'
                                    }
                                  ]}
                                >
                                  <Input
                                    style={{ height: '40px' }}
                                    placeholder='Enter State'
                                  ></Input>
                                </Form.Item>
                              </div>
                              <div className='col-lg-4 col-md-4 col-sm-4 Zipcode'>
                                <Form.Item
                                  onKeyPress={event => {
                                    if (!/[a-zA-Z0-9]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                  label='Zip Code'
                                  name={'Zip'}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Please enter Zip Code'
                                    },
                                    {
                                      min: 5,
                                      message: 'Please enter valid code'
                                      // validator: (rule, value) => {
                                      //   if (value && value.length < 5) {
                                      //     return Promise.reject('');

                                      //   }
                                      //   return Promise.resolve();
                                      // }
                                    }
                                  ]}
                                >
                                  <Input
                                    maxLength={6}
                                    minLength={5}
                                    style={{ height: '40px' }}
                                    placeholder='Enter Zip Code'
                                  ></Input>
                                </Form.Item>
                              </div>
                              <div
                                className='col-lg-4 col-md-4 col-sm-4'
                                style={{ width: '100%' }}
                              >
                                <p style={{ textAlign: 'justify' }}>
                                  <span
                                    style={{
                                      color: '#344767',
                                      fontWeight: 600
                                    }}
                                  >
                                    Terms & Conditions:{' '}
                                  </span>
                                  I agree that the card/bank information
                                  designated herein will be charged in USD the
                                  amount specified on my invoice(s), and I
                                  attest that I am fully authorized to use this
                                  account. I understand this agreement will
                                  automatically renew on each anniversary date
                                  unless a 30-day written notification is
                                  submitted by either party. TransCredit also
                                  reserves the right to change rates associated
                                  with agreement on the anniversary date with
                                  (60) days written notice.
                                </p>
                              </div>
                              <div className='col-lg-6 col-md-6 col-sm-6'>
                                <Form.Item
                                  onKeyPress={event => {
                                    if (!/[a-zA-Z ]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                  label='Approval (Authorized signature): X'
                                  name={'Authorizedsignature'}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        'Please enter Authorized signature'
                                    }
                                    //  {
                                    //   validator: (rule, value) => {
                                    //     if (value && value.length < 5) {
                                    //       return Promise.reject('Please enter valid code');

                                    //     }
                                    //     return Promise.resolve();
                                    //   }
                                    // }
                                  ]}
                                >
                                  <Input
                                    style={{
                                      height: '40px',
                                      fontStyle: 'italic',
                                      fontSize: '20px'
                                    }}
                                    placeholder='Enter Authorized signature'
                                  ></Input>
                                </Form.Item>
                              </div>
                              <div className='col-lg-6 col-md-6 col-sm-6'>
                                <Form.Item
                                  onKeyPress={event => {
                                    if (!/[0-9-]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                  label='Date'
                                  name={'Date'}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Date'
                                    }
                                    //  {
                                    //   validator: (rule, value) => {
                                    //     if (value && value.length < 5) {
                                    //       return Promise.reject('Please enter valid code');

                                    //     }
                                    //     return Promise.resolve();
                                    //   }
                                    // }
                                  ]}
                                >
                                  <Input
                                    maxLength={9}
                                    disabled
                                    value={fielddate}
                                    defaultValue={fielddate}
                                    style={{ height: '40px', color: '#000' }}
                                    placeholder='Enter Date'
                                  ></Input>
                                </Form.Item>
                              </div>
                              <div className='col-lg-6 col-md-6 col-sm-6'>
                                <Form.Item
                                  onKeyPress={event => {
                                    if (!/[a-zA-Z0-9 ]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                  label=' Printed Name: '
                                  name={'PrintedName'}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter  Printed Name '
                                    }
                                    //  {
                                    //   validator: (rule, value) => {
                                    //     if (value && value.length < 5) {
                                    //       return Promise.reject('Please enter valid code');

                                    //     }
                                    //     return Promise.resolve();
                                    //   }
                                    // }
                                  ]}
                                >
                                  <Input
                                    style={{ height: '40px' }}
                                    placeholder='Enter Printed Name'
                                  ></Input>
                                </Form.Item>
                              </div>
                              <div className='col-lg-6 col-md-6 col-sm-6'>
                                <Form.Item
                                  onKeyPress={event => {
                                    if (!/[a-zA-Z0-9 ]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }}
                                  label='Title: '
                                  name={'Title'}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Title '
                                    }
                                    //  {
                                    //   validator: (rule, value) => {
                                    //     if (value && value.length < 5) {
                                    //       return Promise.reject('Please enter valid code');

                                    //     }
                                    //     return Promise.resolve();
                                    //   }
                                    // }
                                  ]}
                                >
                                  <Input
                                    style={{ height: '40px' }}
                                    placeholder='Enter Title'
                                  ></Input>
                                </Form.Item>
                              </div>

                              {/* <div className='col-lg-4 col-md-6 col-sm-6'>
                                <Form.Item onKeyPress={(event) => { if (!/[a-zA-Z0-9 ]/.test(event.key)) { event.preventDefault() } }} label='MC/FF#: ' name={'MCFF'} rules={[{ required: true, message: "Please enter MC/FF# " },
                                   {
                                    validator: (rule, value) => {
                                      if (value && value.length < 5) {
                                        return Promise.reject('Please enter valid code');

                                      }
                                      return Promise.resolve();
                                    }
                                  }
                                ]}
                                >
                                  <Input style={{ height: '40px' }} placeholder='Enter MC/FF#:'></Input>

                                </Form.Item>
                              </div> */}
                            </div>
                          </div>
                        </Form>
                      </>
                    ) : (
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        {api?.Accountlist && api?.Accountlist.length > 0 ? (
                          <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                              <p className='choosecard'>Choose your card</p>
                            </div>
                          </div>
                        ) : (
                          <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                              <p className='choosecard'>Add your card</p>
                            </div>
                          </div>
                        )}

                        <div className='row'>
                          <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div
                              className='duplitabe'
                              style={{
                                border: '1px solid #ccc',
                                borderRadius: '5px'
                              }}
                            >
                              {api?.Accountlist?.map((key, index) => (
                                <>
                                  {
                                    <div
                                      style={{
                                        borderBottom: '1px solid #ccc',
                                        padding: '10px'
                                      }}
                                    >
                                      <Row className='align-items-center'>
                                        <div></div>
                                        <Col
                                          xs={2}
                                          className='text-center col-lg-1 col-sm-2'
                                        >
                                          {/* <label className="checkbox" htmlFor="card1" style={{ marginTop: '10px' }}>
        <input type="checkbox" name="card1" value="red" id="card1" style={{ width: '20px', height: '20px' }} onChange={() => setCardSelected(false)} />
      </label> */}
                                          {/* <input id="myCheckbox"
                                              type="checkbox"
                                              value={key.id}
                                              checked={selectedCheckbox === index} // Check if the checkbox should be checked
                                              onClick={(e) => radiochange(e, index)}
                                              disabled={showPopup1} /> */}
                                          <Checkbox
                                            checked={checked === index}
                                            value={key.id}
                                            onChange={value => {
                                              handleChange(index, value)
                                            }}
                                            inputProps={{
                                              'aria-label': 'controlled'
                                            }}
                                          />
                                        </Col>
                                        {/* <Col xs={2} className="text-center col-lg-2 col-sm-2">
                                            <img style={{ width: '2em' }} className='img-res' src="https://d1qhh2rihbnflk.cloudfront.net/images/master.svg" />
                                          </Col> */}
                                        <Col
                                          xs={8}
                                          className='col-lg-3 col-sm-4'
                                        >
                                          {/* <p className="aboveone">Account No</p> */}
                                          <p className='accountstyle'>
                                            Card Number
                                          </p>
                                          <p className='starnum'>{key?.No}</p>
                                          {/* <p className="aboveone">{key?.Exp}</p> */}
                                        </Col>
                                        {/* <Col xs={2}>
     
      <p className="yrea"></p>
    </Col> */}

                                        <Col
                                          xs={6}
                                          className='col-lg-2 col-sm-2'
                                        >
                                          <>
                                            {/* <span className="trashstyle" style={{ display: (checked === index) ? 'block' : 'none' }}><i onClick={() => setShowPopup1(true)} className="bi bi-trash-fill"></i></span> */}

                                            <>
                                              {showPopup1 && (
                                                <div className='overlay'>
                                                  <div className='delete-confirmation-popup'>
                                                    <div
                                                      className='header'
                                                      style={{
                                                        borderBottom:
                                                          '1px solid #ddd',
                                                        paddingBottom: '10px'
                                                      }}
                                                    >
                                                      <div className='col-md-12'>
                                                        <div className='row'>
                                                          <div
                                                            className='col-md-12'
                                                            style={{
                                                              display: 'flex'
                                                            }}
                                                          >
                                                            {/* <span style={{display:'flex'}}><i class="bi bi-exclamation-triangle-fill"></i><h4>Warning</h4></span> */}
                                                            <h4
                                                              style={{
                                                                fontWeight:
                                                                  '500',
                                                                color: '#344767'
                                                              }}
                                                            >
                                                              Warning
                                                            </h4>
                                                            <span
                                                              style={{
                                                                float: 'right'
                                                              }}
                                                            >
                                                              <i
                                                                class='bi bi-x-circle-fill'
                                                                onClick={() =>
                                                                  setShowPopup1(
                                                                    false
                                                                  )
                                                                }
                                                                style={{
                                                                  position:
                                                                    'absolute',
                                                                  top: '9%',
                                                                  right: '5%',
                                                                  fontSize:
                                                                    '20px',
                                                                  color: 'gray',
                                                                  cursor:
                                                                    'pointer'
                                                                }}
                                                              ></i>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <br></br>
                                                    <h5
                                                      style={{
                                                        fontWeight: '400',
                                                        fontSize: '18px',
                                                        color: '#344767'
                                                      }}
                                                    >
                                                      Are you sure you want to
                                                      delete this account?
                                                    </h5>
                                                    <br></br>
                                                    <div className='col-md-12 button-sec'>
                                                      <button
                                                        type='button'
                                                        className='btn Cancel-button'
                                                        onClick={() =>
                                                          setShowPopup1(false)
                                                        }
                                                      >
                                                        Cancel
                                                      </button>
                                                      <button
                                                        type='button'
                                                        className='btn Delete-button'
                                                        onClick={handleDelete1}
                                                      >
                                                        Delete
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          </>
                                        </Col>
                                      </Row>
                                    </div>
                                  }
                                </>
                              ))}

                              <div
                                style={{
                                  borderBottom: '0px solid #ccc',
                                  padding: '10px'
                                }}
                              >
                                <Row className='align-items-center'>
                                  <div className='col-lg-12'>
                                    <div style={{ textAlign: 'center' }}>
                                      <button
                                        disabled={showPopup1}
                                        style={{
                                          border: 'none',
                                          background: 'none'
                                        }}
                                      >
                                        <i
                                          className='bi bi-plus-circle-fill'
                                          name='card3'
                                          value='red'
                                          id='card3'
                                          style={{
                                            fontSize: '21px',
                                            color: '#2C85EB'
                                          }}
                                          onClick={handleSpanClick}
                                        />{' '}
                                        &nbsp;
                                        <span
                                          className='starnum'
                                          style={{ whiteSpace: 'nowrap' }}
                                          onClick={handleSpanClick}
                                        >
                                          Add New Card
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                  {/* <Col xs={2} className="text-center col-lg-2 col-sm-1">
      <span> <i className='bi bi-plus-circle-fill' name="card3" value="red" id="card3" style={{ fontSize: '21px', color: '#003876' }} onClick={handleSpanClick} /> </span>
    </Col>
    <Col xs={6} className='col-lg-4 col-sm-5'>
      <span className="starnum" style={{ whiteSpace: 'nowrap' }}>Add New Card</span>
    </Col>
    <Col xs={4} className='col-lg-6 col-sm-6'></Col> */}
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-12 col-md-12 col-sm-12'></div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {Values === 2 && open === false && (
                  <>
                    <Form
                      className='billing-name'
                      name='Arcform'
                      layout='vertical'
                      form={Arcform}
                      onFinish={OnSubmit}
                    >
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='row'>
                          <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <Form.Item
                              label='Routing Number'
                              onKeyPress={event => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              name={'Routingnumber'}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Routing Number'
                                },
                                {
                                  min: 9,
                                  message: 'Please enter valid  Routing Number'
                                  // validator: (rule, value) => {
                                  //   if (value && value.length < 9) {
                                  //     return Promise.reject('Please enter valid  Routing Number');
                                  //   }
                                  //   return Promise.resolve();
                                  // }
                                }
                              ]}
                            >
                              <Input
                                style={{ height: '40px' }}
                                maxLength={9}
                                placeholder='Enter Routing Number'
                              ></Input>
                            </Form.Item>
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <Form.Item
                              onKeyPress={event => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              label='Account Number'
                              name='accountno'
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Account Number'
                                },
                                {
                                  min: 4,
                                  message: 'Please enter valid Account Number'
                                  // validator: (rule, value) => {
                                  //   if (value && value.length < 4) {
                                  //     return Promise.reject('.');
                                  //   }
                                  //   return Promise.resolve();
                                  // }
                                }
                              ]}
                            >
                              <Input
                                style={{ height: '40px' }}
                                minLength={4}
                                maxLength={17}
                                placeholder='Enter Account Number'
                              ></Input>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='row'>
                          <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <Form.Item
                              onKeyPress={event => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              label='Confirm Account Number'
                              name='conaccountno'
                              dependencies={['accountno']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please confirm the Account Number'
                                },
                                { validator: validateConfirmAccountNumber }
                              ]}
                            >
                              <Input
                                onPaste={e => e.preventDefault()}
                                onCopy={e => e.preventDefault()}
                                style={{ height: '40px' }}
                                minLength={4}
                                maxLength={17}
                                placeholder='Enter Confirm Account number'
                              ></Input>
                            </Form.Item>
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <Form.Item
                              label='Bank Name'
                              name={'Bank_Name'}
                              rules={[
                                {
                                  required: true,
                                  message: ' Please enter Bank Name'
                                }
                              ]}
                            >
                              <Input
                                style={{ height: '40px' }}
                                placeholder='Enter Bank Name'
                                suffix={
                                  <i
                                    class='bi bi-bank2'
                                    style={{
                                      fontSize: '19px',
                                      color: '#9A9A9A'
                                    }}
                                  />
                                }
                              ></Input>
                            </Form.Item>
                            {/* <div style={{float:'right'}}>
    <i class="bi bi-bank2" style={{fontSize: '19px', color: '#9A9A9A',position:'relative',right:'1rem',top:'-3.1em'}}></i>  
    </div> */}
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='row'>
                          <div className='col-lg-6 col-md-6 col-sm-6'>
                            <Form.Item
                              label='Company Name'
                              name={'Company'}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Company Name'
                                },
                                {
                                  // validator: (rule, value) => {
                                  //   if (value && (value.length < 7)) {
                                  //     return Promise.reject('Please Enter Valid Company Name');
                                  //   }
                                  //   return Promise.resolve();
                                  // }
                                }
                              ]}
                            >
                              <Input
                                style={{ height: '40px' }}
                                placeholder='Enter Company Name'
                              ></Input>
                            </Form.Item>
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-6'>
                            <Form.Item
                              label='Billing Address'
                              name={'BillingAddr'}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Billing Address'
                                }
                              ]}
                            >
                              <Input
                                style={{ height: '40px' }}
                                placeholder='Enter Billing Address'
                                // suffix={<i class="bi bi-geo-alt-fill" style={{ fontSize: '19px', color: '#9A9A9A' }} />}
                              ></Input>
                            </Form.Item>
                            {/* <div style={{float:'right'}}>
    <i class="bi bi-geo-alt-fill" style={{fontSize: '19px', color: '#9A9A9A',position:'relative',right:'1rem',top:'-3.1em'}}></i>  
    </div> */}
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='row'>
                          <div className='col-lg-4 col-md-4 col-sm-4'>
                            <Form.Item
                              label='City'
                              name={'City'}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter City'
                                }
                              ]}
                            >
                              <Input
                                style={{ height: '40px' }}
                                placeholder='Enter City'
                              ></Input>
                            </Form.Item>
                          </div>
                          <div className='col-lg-4 col-md-4 col-sm-4'>
                            <Form.Item
                              label='State'
                              name={'State'}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter State'
                                }
                              ]}
                            >
                              <Input
                                style={{ height: '40px' }}
                                placeholder='Enter State'
                              ></Input>
                            </Form.Item>
                          </div>
                          <div className='col-lg-4 col-md-4 col-sm-4'>
                            <Form.Item
                              label='Zip Code'
                              onKeyPress={event => {
                                if (!/[a-zA-Z0-9]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              name={'Zip_Code'}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Zip Code'
                                },
                                {
                                  min: 5,
                                  message: 'Please enter valid Zip Code'
                                  // validator: (rule, value) => {
                                  //   if (value && value.length < 5) {
                                  //     return Promise.reject('Please enter valid code');

                                  //   }
                                  //   return Promise.resolve();
                                  // }
                                }
                              ]}
                            >
                              <Input
                                maxLength={6}
                                minLength={5}
                                style={{ height: '40px' }}
                                placeholder='Enter Zip Code'
                              ></Input>
                            </Form.Item>
                          </div>
                          <div
                            className='col-lg-4 col-md-4 col-sm-4'
                            style={{ width: '100%' }}
                          >
                            <p style={{ textAlign: 'justify' }}>
                              <span
                                style={{ color: '#344767', fontWeight: 600 }}
                              >
                                Terms & Conditions:{' '}
                              </span>
                              I agree that the card/bank information designated
                              herein will be charged in USD the amount specified
                              on my invoice(s), and I attest that I am fully
                              authorized to use this account. I understand this
                              agreement will automatically renew on each
                              anniversary date unless a 30-day written
                              notification is submitted by either party.
                              TransCredit also reserves the right to change
                              rates associated with agreement on the anniversary
                              date with (60) days written notice.
                            </p>
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-6'>
                            <Form.Item
                              onKeyPress={event => {
                                if (!/[a-zA-Z ]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              label='Approval (Authorized signature): X'
                              name={'Authorizedsignature'}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Authorized signature'
                                }
                              ]}
                            >
                              <Input
                                style={{
                                  height: '40px',
                                  fontStyle: 'italic',
                                  fontSize: '20px'
                                }}
                                placeholder='Enter Authorized signature'
                              ></Input>
                            </Form.Item>
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-6'>
                            <Form.Item
                              onKeyPress={event => {
                                if (!/[0-9-]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              label='Date'
                              name={'Date'}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Date'
                                }
                              ]}
                            >
                              <Input
                                maxLength={9}
                                disabled
                                value={fielddate}
                                defaultValue={fielddate}
                                style={{ height: '40px', color: '#000' }}
                                placeholder='Enter Date'
                              ></Input>
                            </Form.Item>
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-6'>
                            <Form.Item
                              onKeyPress={event => {
                                if (!/[a-zA-Z0-9 ]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              label=' Printed Name: '
                              name={'PrintedName'}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter  Printed Name '
                                }
                              ]}
                            >
                              <Input
                                style={{ height: '40px' }}
                                placeholder='Enter Printed Name'
                              ></Input>
                            </Form.Item>
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-6'>
                            <Form.Item
                              onKeyPress={event => {
                                if (!/[a-zA-Z0-9 ]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              label='Title: '
                              name={'Title'}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Title '
                                }
                              ]}
                            >
                              <Input
                                style={{ height: '40px' }}
                                placeholder='Enter Title'
                              ></Input>
                            </Form.Item>
                          </div>

                          {/* <div className='col-lg-4 col-md-6 col-sm-6'>
                                <Form.Item onKeyPress={(event) => { if (!/[a-zA-Z0-9 ]/.test(event.key)) { event.preventDefault() } }} label='MC/FF#: ' name={'MCFF'} rules={[{ required: true, message: "Please enter MC/FF# " },
                                ]}
                                >
                                  <Input style={{ height: '40px' }} placeholder='Enter MC/FF#:'></Input>

                            </Form.Item>
                          </div> */}
                        </div>
                      </div>
                    </Form>
                  </>

                  //          <div className='col-lg-12 col-md-12 col-sm-12'>
                  //          <div className='row'>
                  //            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                  //            <label class="signup-lab">Routing Number *</label>
                  //              <input name="Routingnumber" style={{border:(error8 &&( Formdata1.Routingnumber.length<9 || Formdata1.Routingnumber.length==0 )) || (valid1 && Formdata1.Routingnumber.length==0)? '1px solid red' : ''}}  onBlur={routblur} onKeyPress={presseventrout} onChange={eventhandler1} maxLength='9' size='9' type="text" autocomplete="off" id="Routingnumber" class="form-control input-text" placeholder="Enter your Routing Number"></input>
                  //              <p className='error-msg'>{Error1.Routingnumber}</p>

                  //            </div>

                  //            <div className='col-lg-6 col-md-6 col-sm-12 col-12 mbl-res'>

                  //             <label class="expiryMonth">Account Number *</label>
                  //                  <input name="accountno"  style={{border:(error9 &&( Formdata1.accountno.length<15 || Formdata1.accountno.length==0 ))|| (valid1 && Formdata1.accountno.length==0)? '1px solid red' : ''}} onBlur={accblur} onKeyPress={presseventacc} onChange={eventhandler1} maxlength={16} size={16}  type="text" autocomplete="off" id="accountno" class="form-control input-text" placeholder="Enter your Account Number"></input>
                  //              <p className='error-msg'>{Error1.accountno}</p>

                  //                     </div>

                  //          </div>
                  //          <div className='row'>
                  //          <div className='col-lg-6 col-md-6 col-sm-12 col-12 ipad-res mbl-res' style={{marginTop:'24px'}}>

                  //             <label class="expiryMonth">Confirm Account Number *</label>
                  //                  <input name="conaccountno" style={{border:(error10 &&(Formdata1.accountno.length<15 || Formdata1.accountno!==Formdata1.conaccountno || Formdata1.conaccountno.length==0 )) || (valid1 && Formdata1.conaccountno.length==0)? '1px solid red' : ''}} onFocus={confoc} onBlur={conaccblur} onKeyPress={presseventconacc} onPaste={pasteevent} onChange={eventhandler1}  type="text" maxlength={16} size={16} autocomplete="off" id="conaccountno" class="form-control input-text" placeholder="Re-enter your Account Number"></input>
                  //                <p className='error-msg'>{Error1.conaccountno}</p>

                  //                     </div>

                  //            <div className='col-lg-6 col-md-6 col-sm-12 col-12 ipad-res mbl-res'style={{marginTop:'24px'}}>
                  //            <label class="signup-lab">Bank Name *</label>
                  //            <div class="input-container">
                  //            <input name="namebank" type="text" style={{border:(error11 &&(Formdata1.namebank.length<10  || Formdata1.namebank.length==0 )) || (valid1 && Formdata1.namebank.length==0)? '1px solid red' : ''}} onBlur={bannameblur} onKeyPress={presseventnamebank} onChange={eventhandler1}  autocomplete="off" id="namebank" class="form-control input-text input-res" placeholder="Enter your Bank Name"></input>
                  //   <div class="image-container">
                  //     <i class="bi bi-bank2" style={{fontSize: '19px', color: '#9A9A9A'}}></i>
                  //   </div>
                  // </div>

                  //    <p className='error-msg'>{Error1.namebank}</p>
                  //            </div>

                  //          </div>
                  //          <div className='row ipad-res mbl-res' style={{marginTop: '24px'}}>

                  //            <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                  //            <label class="signup-lab">Bank Address *</label>
                  //            <div class="input-container">
                  //            <input name="payaddress"  style={{border:(error12 &&(Formdata1.payaddress.length<10  || Formdata1.payaddress.length==0 )) || (valid1 && Formdata1.payaddress.length==0)? '1px solid red' : ''}}  onKeyPress={presseventpayaddr} onChange={eventhandler1} onBlur={banaddblur} type="text" autocomplete="off" id="payaddress" class="form-control input-text input-res" placeholder="Enter your Address"></input>
                  //   <div class="image-container">
                  //   <i class="bi bi-geo-alt-fill" style={{fontSize: '19px', color: '#9A9A9A'}}></i>
                  //     </div>
                  // </div>

                  //                <p className='error-msg'>{Error1.payaddress}</p>

                  //                     </div>
                  //          </div>
                  //          <div className='row ipad-res mbl-res' style={{marginTop: '24px'}}>
                  //            <div className='col-lg-4 col-md-4 col-sm-4 col-12'>
                  //            <label class="signup-lab">City *</label>
                  //            <input name="city" style={{border:(error13 &&(Formdata1.city.length<3  || Formdata1.city.length==0 )) || (valid1 && Formdata1.city.length==0)? '1px solid red' : ''}} onKeyPress={presseventcity1} onBlur={accityblur} onChange={eventhandler1} type="text" autocomplete="off" id="city" class="form-control input-text" placeholder="Enter City"></input>
                  //            <p className='error-msg'>{Error1.city}</p>

                  //            </div>

                  //            <div className='col-lg-4 col-md-4 col-sm-4 col-12 mbl-res'>
                  //            <label class="signup-lab">State *</label>
                  //            <input name="state" style={{border:(error14 &&(Formdata1.state.length<3  || Formdata1.state.length==0 ))|| (valid1 && Formdata1.state.length==0)? '1px solid red' : ''}}   onKeyPress={presseventstate1} onChange={eventhandler1} onBlur={acstateblur} type="text" autocomplete="off" id="state" class="form-control input-text" placeholder="Enter State"></input>
                  //             <p className='error-msg'>{Error1.state}</p>
                  //                     </div>

                  //                     <div className='col-lg-4 col-md-4 col-sm-4 col-12 mbl-res'>
                  //            <label class="signup-lab">Zip Code *</label>
                  //            <input name="zip" onKeyPress={presseventzip1} style={{border:(error15 &&(Formdata1.zip.length<3  || Formdata1.zip.length==0 ))|| (valid1 && Formdata1.zip.length==0)? '1px solid red' : ''}}  onBlur={aczipblur} onChange={eventhandler1}type="text" maxLength='6' size='6' autocomplete="off" id="zip" class="form-control input-text" placeholder="Enter Zip Code"></input>
                  //              <p className='error-msg'>{Error1.zip}</p>
                  //           </div>

                  //          </div>

                  //        </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    } else if (activeStep === 2) {
      return (
        <>
          {Values === 1 && (
            <div className='container ipad-container'>
              <div
                className='row res-card-row'
                style={{ justifyContent: 'center' }}
              >
                <div className='col-lg-11 col-md-12 col-sm-12 order-sm-2 order-md-2 order-2'>
                  <div
                    className='mblcardthank'
                    style={{
                      marginTop: '7em',
                      textAlign: 'center',
                      borderRadius: '5px',
                      background: '#FFF',
                      boxShadow:
                        '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)',
                      padding: '36px 40px'
                    }}
                  >
                    <img
                      src='https://d1qhh2rihbnflk.cloudfront.net/images/Thank-you.svg'
                      style={{ width: '197px', height: '119px' }}
                      alt='Centered Image'
                    />
                    <h2
                      className='inv-dt'
                      style={{
                        fontSize: '24px',
                        color: '#344767',
                        fontWeight: '700',
                        margin: '20px 0'
                      }}
                    >
                      Thank you!
                    </h2>
                    <p
                      className='inv-dt'
                      style={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#1D2B36',
                        textAlign: 'center',
                        margin: '0'
                      }}
                    >
                      Your transaction has been successfully processed. Thank
                      you for your payment. Please click the download tab below
                      to print a copy of your receipt. An email with your
                      credentials was sent to your email address.
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: '20px',
                      display: 'flex',
                      gap: '20px',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <button onClick={downloadTxtFile} className='backtohome'>
                      Download Receipt
                    </button>
                    <button
                      className='loginbtnpage'
                      onClick={() => {
                        window.open('https://portal.transcredit.com/', '_blank')
                      }}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {Values === 2 && (
            // <Thankyou
            //   backHome={backToHome}
            //   setValue={()=>{setValue(3)}}
            // />
            <div className='container ipad-container'>
              <div
                className='row res-card-row'
                style={{ margin: '0 auto', justifyContent: 'center' }}
              >
                <div className='col-lg-11 col-md-12 col-sm-12 order-sm-2 order-md-2 order-2'>
                  <div
                    className='formobicard'
                    style={{
                      marginTop: '7em',
                      textAlign: 'center',
                      borderRadius: '5px',
                      background: '#FFF',
                      boxShadow: '5px 4px 22.4px -1px rgba(0, 0, 0, 0.10)',
                      padding: '36px 40px'
                    }}
                  >
                    <img
                      src='https://d1qhh2rihbnflk.cloudfront.net/images/Thank-you.svg'
                      style={{ width: '197px', height: '119px' }}
                      alt='Centered Image'
                    />
                    <h2
                      className='inv-dt'
                      style={{
                        fontSize: '24px',
                        color: '#344767',
                        fontWeight: '700',
                        margin: '20px 0'
                      }}
                    >
                      Thank you!
                    </h2>
                    <p
                      className='inv-dt'
                      style={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#1D2B36',
                        textAlign: 'center',
                        margin: '0'
                      }}
                    >
                      Your transaction has been successfully processed. Thank
                      you for your payment. Please click the download tab below
                      to print a copy of your receipt. An email with your
                      credentials was sent to your email address.
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: '20px',
                      display: 'flex',
                      gap: '20px',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <button onClick={downloadTxtFile} className='backtohome'>
                      Download Receipt
                    </button>
                    <button
                      className='loginbtnpage'
                      onClick={() => {
                        window.open('https://portal.transcredit.com/', '_blank')
                      }}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {Values === 3 && (
            <LoginCredential backHome={backToHome} Email={email} />
          )}
        </>
      )
    }
  }

  return (
    <div className='overall'>
      <div>
        <Header />
        {Status
          ? [
              <>
                {expired && <Expire />}
                {failed && (
                  <>
                    <Payment backHome={backToHome} values={Values} />
                  </>
                )}
              </>
            ]
          : [
              <>
                <div ref={bottomRef} className='container ipad-container'>
                  <div className='col-lg-12 col-md-12 col-sm-12'>
                    <div
                      className='row res-card-row'
                      style={{
                        margin: '0 auto',
                        justifyContent: 'center',
                        marginBottom: '5em'
                      }}
                    >
                      {/* Existing code */}
                      {!(Values === 3 && activeStep === 2) && (
                        <div className='mbl-ress col-lg-4 col-md-12 col-sm-12 order-sm-2 order-md-2 order-2'>
                          <div
                            className='mblcard card-container'
                            style={{
                              marginTop: '12em',
                              display: activeStep == 1 ? 'block' : 'none'
                            }}
                          >
                            <div className='row'>
                              {Values === 1 ? (
                                <img
                                  className='credimage'
                                  src='https://d1qhh2rihbnflk.cloudfront.net/images/Credit_card.png'
                                  alt='credit-card'
                                />
                              ) : (
                                <img
                                  className='credimage1'
                                  src='https://d1qhh2rihbnflk.cloudfront.net/images/ACH-payment.png'
                                  alt='ach-payment'
                                />
                              )}
                            </div>
                            <div className='row'>
                              <p
                                className='pay-amt'
                                style={{ fontWeight: '600' }}
                              >
                                Payment Amount
                              </p>
                              <p className='valuedo'>${api?.amt?.toFixed(2)}</p>
                            </div>
                            <div className='row'>
                              <div className='fortablepro'>
                                <div className='col-lg-12 col-md-12 col-sm-12'>
                                  <div className='row'>
                                    <div
                                      className='col-lg-12 col-md-6 col-sm-6'
                                      style={{ marginBottom: '15px' }}
                                    >
                                      <div className='row'>
                                        <div
                                          className='col-lg-5 col-md-6 col-6'
                                          style={{ paddingRight: '0' }}
                                        >
                                          <span
                                            className='inv-num1'
                                            style={{ fontSize: '15px' }}
                                          >
                                            Invoice Number
                                          </span>
                                        </div>
                                        <div
                                          className='col-lg-7 col-md-6 col-6'
                                          style={{ paddingLeft: '0' }}
                                        >
                                          <span
                                            className='inv-num'
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            {api?.invoicenumber}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div
                                      className='col-lg-12 col-md-6 col-sm-6'
                                      style={{ marginBottom: '15px' }}
                                    >
                                      <div className='row'>
                                        <div
                                          className='col-lg-5 col-md-6 col-6'
                                          style={{ paddingRight: '0' }}
                                        >
                                          <span
                                            className='inv-dt1'
                                            style={{ fontSize: '15px' }}
                                          >
                                            Invoice Date
                                          </span>
                                        </div>
                                        <div
                                          className='col-lg-7 col-md-6 col-6'
                                          style={{ paddingLeft: '0' }}
                                        >
                                          <span
                                            className='inv-dt'
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            {api?.date}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className='col-lg-12 col-md-6 col-sm-6' style={{ marginBottom: '-5px' }}>
                            <div className='row'>
                              <div>
                                <div>
                                  <button className='button-styledown-border'>
                                    <span className='button-styledown'>
                                      <i className="bi bi-download" style={{ fontSize: '17px', color: '#fff' }}></i>&nbsp;
                                      <span>  Download Invoice</span>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div> */}

                                  <div className='row'>
                                    <div
                                      className='col-lg-12 col-md-6 col-sm-6'
                                      style={{ marginBottom: '15px' }}
                                    >
                                      <div className='row'>
                                        <div
                                          className='col-lg-5 col-md-6 col-6'
                                          style={{ paddingRight: '0' }}
                                        >
                                          <span
                                            className='inv-dt1'
                                            style={{ fontSize: '15px' }}
                                          >
                                            Program
                                          </span>
                                        </div>
                                        <div
                                          className='col-lg-7 col-md-6 col-6'
                                          style={{ paddingLeft: '0' }}
                                        >
                                          <span
                                            className='inv-dt'
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            {api?.program}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div
                                      className='col-lg-12 col-md-6 col-sm-6'
                                      style={{ marginBottom: '15px' }}
                                    >
                                      <div className='row'>
                                        <div
                                          className='col-lg-5 col-md-6 col-6'
                                          style={{ paddingRight: '0' }}
                                        >
                                          <span
                                            className='inv-dt1'
                                            style={{ fontSize: '15px' }}
                                          >
                                            Product
                                          </span>
                                        </div>
                                        <div
                                          className='col-lg-7 col-md-6 col-6'
                                          style={{ paddingLeft: '0' }}
                                        >
                                          <span
                                            className='inv-dt'
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            {api?.product}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {api?.Addonname !== '' && (
                                    <div className='row'>
                                      <div className='col-lg-12 col-md-6 col-sm-6'>
                                        <div className='row'>
                                          <div
                                            className='col-lg-5 col-md-6 col-6'
                                            style={{ paddingRight: '0' }}
                                          >
                                            <span
                                              className='inv-dt1'
                                              style={{ fontSize: '15px' }}
                                            >
                                              Add-On
                                            </span>
                                          </div>
                                          <div
                                            className='col-lg-7 col-md-6 col-6'
                                            style={{ paddingLeft: '0' }}
                                          >
                                            <span
                                              className='inv-dt'
                                              style={{
                                                fontSize: '15px',
                                                fontWeight: 'bold'
                                              }}
                                            >
                                              {api?.Addonname === 'AR'
                                                ? `AR Provider + Credit Reports Monitoring Portfolio`
                                                : `Credit Reports Monitoring Portfolio`}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className='formobicard paid-card card-container'
                            style={{
                              display: activeStep == 2 ? 'block' : 'none'
                            }}
                          >
                            <div
                              className='row'
                              style={{ borderBottom: '1px solid #ddd' }}
                            >
                              <div className='col-lg-2 col-md-1 col-sm-1 col-2'>
                                <i
                                  class='bi bi-check-circle-fill'
                                  style={{ fontSize: '35px', color: '#3ABD5F' }}
                                ></i>
                              </div>
                              <div className='col-lg-10 col-md-11 col-sm-11 col-10'>
                                <p
                                  className='valuedo'
                                  style={{
                                    textAlign: 'left',
                                    border: 'none',
                                    paddingBottom: '0px',
                                    marginBottom: '0px'
                                  }}
                                >
                                  ${api?.amt?.toFixed(2)}
                                </p>
                                {activeStep === 2 && Values === 2 ? (
                                  <p
                                    style={{
                                      fontSize: '18px',
                                      color: '#9A9A9A',
                                      fontWeight: '600'
                                    }}
                                  >
                                    Paid
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      fontSize: '18px',
                                      color: '#9A9A9A',
                                      fontWeight: '600'
                                    }}
                                  >
                                    Paid
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className='row'>
                              <div className='fortablepro'>
                                <div
                                  className='col-lg-12 col-md-12 col-sm-12'
                                  style={{ marginTop: '20px' }}
                                >
                                  <div className='row'>
                                    <div
                                      className='col-lg-12 col-md-6 col-sm-6'
                                      style={{ marginBottom: '15px' }}
                                    >
                                      <div className='row'>
                                        <div className='col-lg-5 col-md-6 col-6'>
                                          <span
                                            className='inv-num1'
                                            style={{ fontSize: '15px' }}
                                          >
                                            Invoice Number
                                          </span>
                                        </div>
                                        <div className='col-lg-7 col-md-6 col-6'>
                                          <span
                                            className='inv-num'
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            {api?.invoicenumber}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div
                                      className='col-lg-12 col-md-6 col-sm-6'
                                      style={{ marginBottom: '15px' }}
                                    >
                                      <div className='row'>
                                        <div className='col-lg-5 col-md-6 col-6'>
                                          <span
                                            className='inv-dt1'
                                            style={{ fontSize: '15px' }}
                                          >
                                            Invoice Date
                                          </span>
                                        </div>
                                        <div className='col-lg-7 col-md-6 col-6'>
                                          <span
                                            className='inv-dt'
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            {api?.date}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div
                                      className='col-lg-12 col-md-6 col-sm-6'
                                      style={{ marginBottom: '15px' }}
                                    >
                                      <div className='row'>
                                        <div className='col-lg-5 col-md-6 col-6'>
                                          <span
                                            className='inv-dt1'
                                            style={{ fontSize: '15px' }}
                                          >
                                            Program
                                          </span>
                                        </div>
                                        <div className='col-lg-7 col-md-6 col-6'>
                                          <span
                                            className='inv-dt'
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            {api?.program}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div className='col-lg-12 col-md-6 col-sm-6'>
                                      <div className='row'>
                                        <div className='col-lg-5 col-md-6 col-6'>
                                          <span
                                            className='inv-dt1'
                                            style={{ fontSize: '15px' }}
                                          >
                                            Product
                                          </span>
                                        </div>
                                        <div className='col-lg-7 col-md-6 col-6'>
                                          <span
                                            className='inv-dt'
                                            style={{
                                              fontSize: '15px',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            {api?.product}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {api?.Addonname !== '' && (
                                    <div className='row'>
                                      <div className='col-lg-12 col-md-6 col-sm-6'>
                                        <div className='row'>
                                          <div className='col-lg-5 col-md-6 col-6'>
                                            <span
                                              className='inv-dt1'
                                              style={{ fontSize: '15px' }}
                                            >
                                              Add-On
                                            </span>
                                          </div>
                                          <div className='col-lg-7 col-md-6 col-6'>
                                            <span
                                              className='inv-dt'
                                              style={{
                                                fontSize: '15px',
                                                fontWeight: 'bold'
                                              }}
                                            >
                                              {api?.Addonname === 'AR'
                                                ? `AR Provider + Credit Reports Monitoring Portfolio`
                                                : `Credit Reports Monitoring Portfolio`}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className='mbl-ress col-lg-7 col-md-12 col-sm-12 order-sm-1 order-md-1 order-1'>
                        <div
                          className={
                            activeStep === 2
                              ? ''
                              : 'card-container res-card-container'
                          }
                        >
                          <div className='row'>{renderContent()}</div>

                          <div className='row' style={{ marginTop: '24px' }}>
                            <div className='col-lg-3 col-md-3 col-sm-3 col-6'>
                              {((activeStep === 1 &&
                                Values === 1 &&
                                api['Addon'] !== '0') ||
                                open === true ||
                                (activeStep === 1 && Values === 2)) && (
                                <>
                                  <button
                                    style={{ textTransform: 'capitalize' }}
                                    className='button-stylebac'
                                    onClick={prevStep}
                                  >
                                    Back
                                  </button>
                                </>
                              )}
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-6 col-6'>
                              {/* {Values == 1 && showFailedLabel && (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    border: '1px solid #f13939f7',
                                    padding: '10px 15px',
                                    gap: '10px',
                                    borderLeft: '10px solid #f13939f7'
                                  }}
                                >
                                  <ErrorIcon style={{ color: '#f13939f7' }} />
                                  <p
                                    style={{ margin: '0', color: '#f13939f7' }}
                                  >
                                    {failedText}
                                  </p>
                                </div>
                              )} */}
                            </div>
                            <div className='col-lg-3 col-md-3 col-sm-3 col-6'>
                              {Values === 1 &&
                              open === true &&
                              activeStep === 1 ? (
                                <button
                                  className={`button-stylepay ${
                                    activeStep === 1 ? 'Login' : ''
                                  }`}
                                  onClick={nextStep}
                                  disabled={isloading1}
                                  style={
                                    activeStep === 1
                                      ? {
                                          padding: '11px 23px',
                                          display: 'flex',
                                          cursor: isloading1
                                            ? 'not-allowed'
                                            : 'pointer'
                                        }
                                      : null
                                  }
                                >
                                  <span>
                                    {'Pay' + ' $' + api?.amt.toFixed(2)}
                                  </span>

                                  {isloading1 && activeStep === 1 && (
                                    <span
                                      style={{
                                        marginLeft: '20px',
                                        display: 'block'
                                      }}
                                    >
                                      {' '}
                                      <i class='fa fa-spinner fa-spin'></i>
                                    </span>
                                  )}
                                </button>
                              ) : Values === 2 &&
                                open === false &&
                                activeStep === 1 ? (
                                <button
                                  className={`button-stylepay ${
                                    activeStep === 1 ? 'Login' : ''
                                  }`}
                                  onClick={nextStep}
                                  disabled={isloading1}
                                  style={
                                    activeStep === 1
                                      ? {
                                          padding: '11px 23px',
                                          display: 'flex',
                                          cursor: isloading1
                                            ? 'not-allowed'
                                            : 'pointer'
                                        }
                                      : null
                                  }
                                >
                                  <span>Pay ${api?.amt.toFixed(2)}</span>

                                  {isloading1 && activeStep === 1 && (
                                    <span
                                      style={{
                                        marginLeft: '20px',
                                        display: 'block'
                                      }}
                                    >
                                      {' '}
                                      <i class='fa fa-spinner fa-spin'></i>
                                    </span>
                                  )}
                                </button>
                              ) : (
                                // <Button  className='button-stylepay' variant="contained">
                                //   {activeStep === 2 ? title : title + '$' + api.amt}

                                //   {
                                //     (isloading && activeStep === 1) &&
                                //     <Icon style={{ marginLeft: '10px' }} className="fa fa-spinner fa-spin" />
                                //   }

                                // </Button>

                                api?.Accountlist &&
                                api?.Accountlist.length > 0 &&
                                activeStep === 1 && (
                                  <button
                                    className={`button-stylepay ${
                                      activeStep === 1 ? 'Login' : ''
                                    }`}
                                    onClick={InvoicePayment}
                                    disabled={isloading == true}
                                    style={
                                      activeStep === 1
                                        ? {
                                            padding: '11px 23px',
                                            display: 'flex',
                                            cursor: isloading
                                              ? 'not-allowed'
                                              : 'pointer'
                                          }
                                        : null
                                    }
                                  >
                                    <span>Pay ${(api?.amt).toFixed(2)}</span>

                                    {isloading && activeStep === 1 && (
                                      <span
                                        style={{
                                          marginLeft: '20px',
                                          display: 'block'
                                        }}
                                      >
                                        {' '}
                                        <i class='fa fa-spinner fa-spin'></i>
                                      </span>
                                    )}
                                  </button>

                                  // <Button onClick={InvoicePayment} className='button-stylepay' variant="contained">
                                  //   {'Pay $' + api.amt}

                                  // </Button>
                                )
                              )}
                            </div>
                          </div>
                          {Values == 1 && showFailedLabel && (
                            <p
                              style={{
                                fontSize: '15px',
                                color: '#f13939f7',
                                marginTop: '1rem'
                              }}
                            >
                              <span style={{ fontSize: '16px' }}>*</span>{' '}
                              {failedText}
                            </p>
                          )}
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ]}
      </div>

      <Box
        sx={{
          flexGrow: 1,
          alignSelf: 'end',
          position: 'fixed',
          bottom: '0',
          width: '100%'
        }}
      >
        <AppBar position='static' sx={{ background: '#002b5c' }}>
          <Toolbar sx={{ textAlign: 'center' }}>
            <Typography
              className='copy-text'
              variant='h6'
              component='div'
              sx={{
                flexGrow: 1,
                fontSize: '12px',
                color: '#6bc1cd',
                fontWeight: '400',
                marginTop: '10px'
              }}
            >
              � 2024 - Transcredit, Inc. - All Rights Reserved Licensed & Bonded
              | Licence No. COM 9900337 | State of Florida Department of Banking
              & Finance
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <ToastContainer autoClose={2000} />
    </div>
  )
}

export default Paymentmethod
